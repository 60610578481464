import React, {useEffect, useState, useRef, useContext} from 'react'
import axios from 'axios'
import {useAuthHeader} from 'react-auth-kit'
import {updateClient, fetchClients} from '../../../helpers/api'
import {TimerContext} from '../../../contexts/TimerContext'

const ClientDropdown = () => {
const [clients, setClients] = useState([]);
const {currentTimer, setCurrentTimer} = useContext(TimerContext);
const [isLoading, setIsLoading] = useState(true);

const authHeader = useAuthHeader();

  const handleChange = (e) => {
    setCurrentTimer({...currentTimer, client_id: e.target.value, client_name: e.target.options[e.target.selectedIndex].text});
    updateClient(e.target.value, e.target.options[e.target.selectedIndex].text, currentTimer.id, authHeader());
  }

useEffect(() => {
  fetchClients().then(data => {
    setClients(data);
    setIsLoading(false);
  }).catch(err => {
    console.log(err);
  });
}, [])


  return (
    <>
        <select onChange={handleChange} style={Styles.input} value={currentTimer == null || currentTimer.client_id == null ? 'Select a client...' : currentTimer.client_id}>
            {!isLoading && clients.length > 0 ? clients.map((client) => {
                return (
                    <option key={client.id} value={client.id}>{client.name}</option>
                )
            }) : <option disabled>Loading...</option>}
        </select>
    </>
  )
}

const Styles = {
    input: {
        backgroundColor: '#ede0d4',
        border: 'solid 1px #BFBFBF',
        borderRadius: '5px',
        padding: '1rem',
        fontSize: '1rem',
        color: '#6b705c',
        cursor: 'pointer',
    },
};

export default ClientDropdown