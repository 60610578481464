import React, {useState} from "react";
import ClientBadge from "./ClientBadge";
import moment from 'moment';

const Styles = {
  previousSelector: {
    width: "100%",
    margin: "auto",
    backgroundColor: "#6b705c",
    cursor: "pointer",
    display: "grid",
    border: '2px solid transparent',
    borderRadius: "5px",
    padding: "0.75rem",
    transition: "all 0.2s ease-in-out",
    boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.2)",
  },
  previousSelectorHovered: {
    width: "100%",
    margin: "auto",
    backgroundColor: "#6b705c",
    cursor: "pointer",
    display: "grid",
    borderRadius: "5px",
    padding: "0.75rem",
    border: '2px solid #ddb892',
    borderColor: 'linear-gradient(to right, red, purple)',
    transition: "all 0.2s ease-in-out",
    boxShadow: "unset",
  },
  infoContainer: {
    backgroundColor: "#6b705c",
    display: "flex",
    gap: "1rem",
    width: "100%",
    fontWeight: 'bold',
    fontFamily: 'Kanit'
  },
  dateContainer: {
    display: "flex",
    backgroundColor: "lightgray",
    width: "25%",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "4px",
    color: '#6b705c',
    fontFamily: 'Kanit',
    justifyContent: "center",
    padding: "0.5rem 0.25rem",
  },
  previousSelectorDate: {
    fontWeight: "600",
    textTransform: "uppercase",
    letterSpacing: ".075em",
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  clientTimeContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  badge: {
    display: "flex",
    justifyContent: "space-between",
    color: '#eee'
  },
  previousSelectorTime: {
    float: "right",
    fontWeight: "bolder",
  },
  spanTimeContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
  descriptionContainer: {
    display: "grid",
    whiteSpace: "nowrap",
  },
  previousSelectorDescription: {
    display: "block",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: '#eee',
    margin: "0",
  },
  previousSelectorDescriptionNone: {
    display: "block",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: '#ff7675',
    margin: "0",
  },

};

const PreviousProject = ({ projectInfo, index, handleChildClick }) => {
  // get dateObj from projectInfo
  const dateObj = moment(projectInfo.date).format();
  // get UTCmonth short name from date
  const month = moment(dateObj).utc().format('MMM');
  // get UTCdate from date
  const date = moment(dateObj).utc().format('DD');

  const [isHovered, setIsHovered] = useState(false)


  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleChildClick(projectInfo)}
      key={projectInfo.id}
      
      style={isHovered ? Styles.previousSelectorHovered : Styles.previousSelector}
    >
      <div style={Styles.infoContainer}>
        <div style={Styles.dateContainer}>
          <span style={Styles.previousSelectorDate}><span>{month}</span><span>{date}</span></span>
        </div>
        <div style={Styles.clientTimeContainer}>
          <div style={Styles.badge}>
            <ClientBadge data={projectInfo}/>
            <span style={Styles.previousSelectorTime}>
              { projectInfo.hours !== null && projectInfo.minutes !== null && projectInfo.seconds !== null ? `${projectInfo.hours}:${projectInfo.minutes}:${projectInfo.seconds}` : "N/A"}
            </span>
          </div>
          <div style={Styles.spanTimeContainer}>
            <div style={Styles.descriptionContainer}>
              <p style={projectInfo.description ? Styles.previousSelectorDescription : Styles.previousSelectorDescriptionNone}>
                {projectInfo.description ? projectInfo.description : "No description"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

export default PreviousProject;
