import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

const UserProvider = ({ children }) => {

    const [user, setUser] = useState(null);

    // attempt to use refresh token to get new access token
    const refreshToken = () => {
        // refresh token
        const refreshToken = JSON.parse(localStorage.getItem('user_auth'));
        const refreshConfig = {
            method: 'post',
            url: 'https://data.mintleafmarketing.com/auth/refresh',
            data : {
                "refresh_token": refreshToken.refresh_token
            }
        };
        axios(refreshConfig)
        .then(function (response) {
            localStorage.setItem('user_auth', JSON.stringify(response.data.data));           
            getUserInfo(response.data.data);
        })
        .catch(function (error) {
            console.log(error);
            localStorage.removeItem('user_auth');
            localStorage.removeItem('user');
            setUser(null);
        });
    }

    const getUserInfo = async (auth) => {
        var config = {
            method: 'get',
            headers: { 
                'Authorization': `Bearer ${(auth.access_token)}`
            },
            url: `https://data.mintleafmarketing.com/users/me?fields=*.*`,
          };
      
          axios(config)
          .then(function (response) {
            console.log(response.data.data);
            localStorage.setItem('user', JSON.stringify(response.data.data));
            setUser(response.data.data);
          })
          .catch(function (error) {
              // check if refresh token is expired
                if (error.response.status === 401) {
                    refreshToken();
                }
          });
    }

    const login = (token) => {
        localStorage.setItem('user_auth', JSON.stringify(token));
        getUserInfo(JSON.parse(localStorage.getItem('user_auth')));
    }
    
    const logout = (user) => {       
        localStorage.removeItem('user');
        localStorage.removeItem('user_auth');
        setUser(null);
    }

    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem('user_auth'));
        if (auth) {
            getUserInfo(auth);
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            { children }
        </UserContext.Provider>
    )
}

export default UserProvider;