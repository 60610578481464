import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import axios from 'axios';
import SelectedEntries from './SelectedEntries';
import moment from 'moment';
import {useAuthHeader, useAuthUser} from 'react-auth-kit'
import {getTimeSheetDropDown, getClientLog, getTimeSheetData} from '../../../helpers/api'

const TimeSheetModal = ({handleShow}) => {
    const [entryDrafts, setEntryDrafts] = useState([]);
    const [checkedEntries, setCheckedEntries] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);    
    const [selectedClientName, setSelectedClientName] = useState('');
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const authHeader = useAuthHeader();
    const user = useAuthUser();

    const handleChange = (e) => {
      setSelectedClient(e.target.value);
      setSelectedClientName(e.target.selectedOptions[0].text);
      
      getClientLog(e.target.value, user().team.id, authHeader()).then(data => {
        setEntryDrafts(data)
        setCheckedEntries(data.map(entry => entry.id))
      }).catch(error => {
        console.log(error)
      });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
  }  
  
  useEffect(() => {
    
        getTimeSheetDropDown(authHeader(), user().team.id).then(data => {
          setClients(data)
        }).catch(error => {
          console.log(error)
        });

        if(checkedEntries.length > 0) {
          setSubmitEnabled(true);
      } else {
          setSubmitEnabled(false);
      }

    }, [checkedEntries])

    /* Modal Entries */
    const handleCheck = (e) => {
        // log id of checked entry if checked
        if (e.target.checked) {
            // add to array of checked entries if not already in array
            if (!checkedEntries.includes(parseInt(e.target.value))) {
                setCheckedEntries([...checkedEntries, parseInt(e.target.value)]);
            }
        } else {
            // remove from array of checked entries if in array
            if (checkedEntries.includes(parseInt(e.target.value))) {
                setCheckedEntries(checkedEntries.filter(entry => entry !== parseInt(e.target.value)));
            }
        }


    }

    const submitHandler = (e) => {
        
        e.preventDefault();
        
        // update checked entries with published status
        const updateEntries = checkedEntries.map(entry => {
            return {
                id: entry,
                status: 'published'
            }
        });

        var config = {
            method: 'post',
            url: 'https://data.mintleafmarketing.com/items/timesheet',
            headers: { 
              'Authorization': `${(authHeader())}`, 
            },
            data : {
                "time_entry": updateEntries,
                "client_id": selectedClient,
                "client_name": selectedClientName,
                "status": "published",
            }
          };
      
          axios(config)
          .then(function (response) {
            // successfully created timesheet, now get the timesheet data from the API and create timesheet in wave
            getTimeSheetData(response.data.data.id, authHeader()).then(data => {
              handleShow(false)
            });
          })
          .catch(function (error) {
            console.log(error);
          });
    }

  return (
    <div style={Styles.modalContainer}>
        <div style={Styles.headerContainer}>
            <span>
            Create New Timesheet
            </span>
            <button onClick={() => handleShow(false)} style={Styles.exitModalButton} >
            <FontAwesomeIcon style={Styles.xIcon} icon={faCircleXmark}/>
            </button>
        </div>
        <form onSubmit={handleSubmit}>
        <div style={Styles.contentContainer}>
            <div style={Styles.clientSelectorContainer}>
                <label style={Styles.formLabel}>Client</label>
                <select style={Styles.selectInput} name="client" onChange={handleChange}>
                    <option value="" disabled selected>Select a client...</option>
                    {clients.map((client,index) => {
                        return (
                            <option key={index} value={client.client_id}>{client.client_name}</option>
                        )}
                    )}
                </select>
            </div>
            { selectedClient.length !== 0 &&
            <>
            <div style={{margin:'1rem 0'}}>
                <label>Selected Entries ({checkedEntries.length})</label>
                <SelectedEntries handleCheck={handleCheck} entryDrafts={entryDrafts}/>
            </div>
            <div>
                <input name="roundEntries" value="false" type='checkbox' checked='yes' disabled/>
                <label>Round Timesheet?</label>
            </div> </>}
        </div>
            <button type="submit" onClick={submitHandler} disabled={!submitEnabled} style={submitEnabled ? {...Styles.createTimeSheetButton, ...Styles.buttonEnabled} : {...Styles.createTimeSheetButton, ...Styles.buttonDisabled}}>Create Timesheet</button>
        </form>
    </div>
  )
}

const Styles = {
    modalContainer: {
        display: 'grid',
        width: '50vw',
        maxWidth: '500px',
        alignItems: 'center',
        border: '1px solid white',
        color: 'white',
        gap: '1rem',
        padding: '.5rem',
        backgroundColor: 'rgb(183, 183, 164)',
        position:"fixed",
        zIndex: '1',
        padding: '1rem',
    },
    buttonEnabled: {
        backgroundColor: '#28f57f',
        color: '#00710a',
    },
    formLabel: {
        marginBottom: '0.5rem',
    },
    buttonDisabled: {
        backgroundColor: '#0f0f0f',
        opacity: '.5',
        cursor: 'not-allowed',
        color: 'white',
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize:'1.25rem'
    },
    contentContainer: {
        display: 'grid',
    },
    clientSelectorContainer: {
        display:'grid',
    },
    createTimeSheetButton: {
        backgroundColor: '#0f0f0f',
        borderRadius: '4px',
        color: '#fff',
        border: 'none',
        display:'flex',
        justifyContent: 'space-around',
        cursor: 'pointer',
        padding: '0.5rem 1rem',
        alignSelf: 'center',
        width: 'auto',
        margin: '1rem 0',
        backgroundColor: '#0f0f0f',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    exitModalButton: {
        cursor: 'pointer',
        backgroundColor: '#rgb(192, 190, 171)',
        padding: '0.5rem 1rem',
        borderRadius: '5px',
        border: 'none',
        display: 'flex',
        height: 'auto',
    },
    xIcon: {
        color: '#ff7675',
        fontSize: '1rem',
    },
    selectInput: {
        width: '100%',
        backgroundColor: 'rgb(107, 112, 92)',
        border: '1px solid rgb(191, 191, 191)',
        borderRadius: '5px',
        padding: '1rem',
        fontSize: '1rem',
        color: 'rgb(191, 191, 192)',
        cursor: 'pointer',
    }
}

export default TimeSheetModal