import React from 'react'


const Spinner = () => {
  return (
    <>
        <svg style={{margin: 'auto', backgroundColor: 'rgba(241, 242, 243, 0)', display: 'block', shapeRendering: 'auto'}} width="251px" height="251px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="rotate(0 50 50)">
        <rect x="36" y="19.5" rx="14" ry="9.5" width="28" height="19" fill="#985d4e">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
        </rect>
        </g><g transform="rotate(120 50 50)">
        <rect x="36" y="19.5" rx="14" ry="9.5" width="28" height="19" fill="#e0dcb8">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
        </rect>
        </g><g transform="rotate(240 50 50)">
        <rect x="36" y="19.5" rx="14" ry="9.5" width="28" height="19" fill="#aca730">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
        </rect>
        </g></svg>
    </>
  )
}

export default Spinner