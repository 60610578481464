import React, { useEffect, useRef, useState } from 'react';

const SelectedEntries = ({entryDrafts, handleCheck}) => {

    useEffect(() => {
        console.log('entryDrafts: ', entryDrafts);
    }, [])


  return (
    <div style={Styles.clientDrafts}>
        {typeof(entryDrafts) !== 'undefined' && entryDrafts.length > 0 && entryDrafts.map((entry) => {
            return (
                <div style={Styles.clientDraftEntries} key={entry.id}>
                    <input onChange={handleCheck} type='checkbox' defaultChecked value={entry.id} />
                    <span style={Styles.clientDraftTime}>{entry.hours}:{entry.minutes}:{entry.seconds}</span>
                    <span style={Styles.clientDraftDescription}>{entry.description}</span>
                </div>
            )
        })}
    </div>
  )
}

const Styles = {
    clientDrafts: {
        display: 'grid',
        background: "rgb(107, 112, 92)",
        padding: "0.5rem 1rem",
        borderRadius:"5px",
        overflowY:"scroll",
        overflowX:"hidden",
        maxHeight:"125px",
    },
    clientDraftEntries: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 7fr',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        justifyItems: 'start',  
    },
    clientDraftTime: {
        padding: '0.5rem 1rem',
    },
    clientDraftDescription: {
        display: 'block',
        overflow: 'hidden',
        padding: '0.5rem 1rem',
        width: '100%',
        textOverflow: 'ellipsis',
    }
}

export default SelectedEntries