import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import {useSignOut} from 'react-auth-kit';
import MintLeafLogo from '../../svg/mintLeafLogo.svg'

const LogoutPage = () => {
    const signOut = useSignOut();

    const navigate = useNavigate();
    useEffect(() => {
        signOut();
        navigate('/login');
    }, [])

    return (
            <div style={Styles.logInFormBackground}>
                <div style={Styles.logInContainer}>
                    <span>Logging out...</span>
                </div>
            </div>
    )
}

const Styles = {
    logInForm: {
        display: 'grid',
        position: 'fixed',
        top: '0',
        left:'0',
        width: '100%',
        height: '100%',
        justifyItems: 'center',
        alignItems: 'center',
    },
    logInFormBackground: {
        backgroundColor: '#6b705c',
        position: 'fixed',
        width: '50%',
        height: '50%',
        justifyItems: 'center',
        alignItems: 'center',
        display: 'grid',
        borderRadius: '10px',
    },
    logInContainer: {
        display: 'grid',
        justifyItems: 'center',
        gap: '1rem'
    },
    logo: {
        width: '100%',
        height: '100%', 
        objectFit: 'contain',   
        marginBottom: '1rem'
    },
    logInButton: {
        backgroundColor: '#a5a58d',
        borderRadius: '4px',
        color: '#f0efeb',
        border: '1px solid transparent',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        fontWeight: '700',
        width: '8rem',
        lineHeight: '1rem',
      },
      logInButtonHovered: {
        backgroundColor: '#C0BEAB',
        borderRadius: '4px',
        color: '#f0efeb',
        border: '1px solid white',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        fontWeight: '700',
        width: '8rem',
        lineHeight: '1rem'
      },
      fieldInputs: {
        borderRadius: '4px',
        backgroundColor: '#ede0d4',
        color: '#6b705c'
      }
}

export default LogoutPage;