import React, { useState, useEffect } from 'react'
import ClientBadge from '../TimerDropdown/ClientBadge'



const TimeEntiresBox = ({data, handleEntriesModalShow}) => {
    const [isHovered, setIsHovered] = useState(false);
      
  return (
          <button
        //   onClick={() => handleEntriesModalShow(data)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
           style={isHovered ? Styles.boxHovered : Styles.box}
           >
              <span style={Styles.statusSpan}>{data.status}</span>
              <span style={Styles.timeSpan}>{ data.hours !== null && data.minutes !== null && data.seconds !== null ? `${data.hours}:${data.minutes}:${data.seconds}` : "N/A"}</span>
              <ClientBadge data={data}></ClientBadge>
              <span style={Styles.timeEntryDescription}>{data.description ? data.description : "No description"}</span>
              <img style={{width: '2.5rem', height: '2.5rem', objectFit: 'cover', justifySelf: 'end', borderRadius:'4px'}} src={data.user_created.avatar !== null ? `https://data.mintleafmarketing.com/assets/${data.user_created.avatar}` : `https://ui-avatars.com/api/?background=d3d3d3&color=6b705c&name=${data.user_created.email !== null ? data.user_created.email.charAt(0) : '?'}`}/>
          </button>
  )
}

const Styles = {
    box: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        justifyItems: 'start',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#6b705c',
        color: '#f0efeb',
        border: '1px solid transparent',
        borderRadius: '4px',
        width: '100%',
        padding: '1rem',
        gridGap: '1rem',
    },
    boxHovered: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        justifyItems: 'start',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#b7b7a4',
        color: '#f0efeb',
        border: '1px solid #ddb892',
        borderRadius: '4px',
        width: '100%',
        padding: '1rem',
        gridGap: '1rem',
    },
    statusSpan: {
        color: '#faedcd',
        backgroundColor: '#ddb892',
        width: 'auto',
        alignItems: 'center',
        display: 'flex',
        textAlign: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem 1rem',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    timeSpan: {
        color: '#f0efeb'
    },
    timeEntryDescription: {
        display: "block",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: '#f0efeb'
    },
    editButton: {
        backgroundColor: '#0f0f0f',
        borderRadius: '4px',
        color: 'lightgreen',
        border: '1px solid white',
        display:'flex',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        marginRight: '1rem',
        width: '2vw'
    },
    modalContainer: {
        display: 'grid',
        position: 'fixed',
        top: '0',
        left:'0',
        width: '100%',
        height: '100%',
        justifyItems: 'center',
        alignItems: 'center',
      },
    modalOverlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
        width:"100%",
        height:"100vh",
        position: "fixed",
        top:"0",
        left:"0",
      }
}


export default TimeEntiresBox