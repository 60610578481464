import React from 'react';
import PreviousProject from './PreviousProject';

const Styles = {
  previousProjectsContainer: {
    display: 'grid',
    width: '100%'
  },
  recentText: {
    fontSize: '1rem',
    color: '#eee',
    fontWeight: 'bold',
    marginBottom: '1rem',
    fontFamily: 'Kanit'
  },
  previousProjectsList: {
    display: 'grid',
    gap: '1rem',
    width: '100%',
    maxHeight: '19.5rem',
    overflowY: 'scroll'
  },
}

const PreviousProjects = ({data, handleChildClick}) => {
    
  return (
    <div style={Styles.previousProjectsContainer}>
      <span style={Styles.recentText}>Recent Timers</span>

      <div style={Styles.previousProjectsList}>
        {data.map((project) => {
            return (
              <PreviousProject handleChildClick={handleChildClick} projectInfo={project} key={project.id}/>
            )
        })}
        </div>
    </div>
  )
}

export default PreviousProjects