import React, { useState, useContext, useEffect } from 'react';
import {  BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import LoginPage from './components/LoginPage/LoginPage';
import LogoutPage from './components/LogoutPage/LogoutPage';
import TimeSheetPage from './components/TimeSheetsPage/TimeSheetPage';
import { LOGIN_PATH, DASHBOARD_PATH, TIMESHEET_PATH, PROPOSAL_PATH } from './views'
import { RequireAuth } from 'react-auth-kit'
import ViewAllTimeSheet from './components/Dashboard/TimeSheets/ViewAllTimeSheet';
import ProposalPage from './components/Proposals/ProposalPage';
import { TimerContext } from './contexts/TimerContext';
import { useAuthUser, useAuthHeader, useIsAuthenticated } from 'react-auth-kit';
import {fetchCurrentTimerForUser} from './helpers/api';



const AppRoutes = () => {
  const user = useAuthUser();
  const auth = useAuthHeader();
  const [currentTimer, setCurrentTimer] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  
  // load the timer data from the API
  useEffect(() => {
    if(isAuthenticated()) {
      // Redirect to Dashboard
      fetchCurrentTimerForUser(auth(), user().id).then(data => {
        if(typeof(data) !== 'undefined'){
          setCurrentTimer(data);
        } else {
          setCurrentTimer(null);
        }
      });
    }
    else {
        // Redirect to Login
        
    }
  }, []);

  return (
    <Router>
              <TimerContext.Provider value={{currentTimer, setCurrentTimer}}>
                <Routes>
                  <Route path={LOGIN_PATH} element={<LoginPage />} />
                  <Route path={'/logout'} element={<LogoutPage />} />
                  <Route path={DASHBOARD_PATH} element={
                  <RequireAuth loginPath={'/login'}>
                      <Dashboard />
                  </RequireAuth>}/>
                  <Route path="/timesheets" element={
                    <RequireAuth loginPath={'/login'}>
                    <ViewAllTimeSheet />
                  </RequireAuth>}/>
                  {/* add time id to the end of timesheetspage */}
                  <Route path={TIMESHEET_PATH+'/:timesheetId'} element={<TimeSheetPage />}/>
                  <Route path={PROPOSAL_PATH+'/:proposalId'} element={<ProposalPage />}/>
                  <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </main>
                  }
                  />
                </Routes>
        </TimerContext.Provider>
            </Router>
  )
}

const Styles = {
    appModalContainer: {
      display: 'grid',
      justifyItems: 'center',
      alignItems: 'center'
    }
  }

export default AppRoutes