import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Accent from '../../svg/accent.svg';
import MintLeafLogo from '../../svg/mintLeafLogo.svg';
import StopWatch from '../../svg/stopwatch.svg';
import Note from '../../svg/description.svg';
import moment from 'moment';
import './TimeSheetPage.css'
import { useParams } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const TimeSheetPage = () => {

    var momentDurationFormatSetup = require("moment-duration-format");

    let { timesheetId } = useParams();
    const [timeEntries, setTimeEntries] = useState([]);
    const [timesheetData, setTimesheetData] = useState({});
    const [totalTimeSpent, setTotalTimeSpent] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const callURL = 'https://data.mintleafmarketing.com/items/timesheet';

    // get dateObj from projectInfo
    // const dateObj = moment(date.date).format();
    // // get UTCmonth short name from date
    // const month = moment(dateObj).utc().format('MMM');
    // // get UTCdate from date
    // const day = moment(dateObj).utc().format('DD');

    // const timeSpent = `${hours}:${minutes}`

      
      const fetchSheetsData = async () => {
        axios({
            method: 'get',
            url: `${callURL}/${timesheetId}?fields=*.*`,
            headers: { 
              'Authorization': `Bearer 3db0f55f-ed56-4c51-84d0-5870a9aea4be` 
            }
          })
        .then(function (response) {
            setTimesheetData(response.data.data);

            // sort time entries by date descending
            const sortedTimeEntries = response.data.data.time_entry.sort((a, b) => {
                return moment(b.date).diff(moment(a.date));
            });

            setTimeEntries(sortedTimeEntries);
            // add up all the time spent and set it to the total time spent
            setTotalTimeSpent(response.data.data.time_entry.reduce((acc, curr) => {
                // add hours minutes and seconds together
                const totalSeconds = parseInt(curr.hours)*3600 + parseInt(curr.minutes)*60 + parseInt(curr.seconds);
                return acc + totalSeconds;
            }, 0));
            setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
      }

      useEffect(() => {
          fetchSheetsData();
      }, [])

      const roundedTime = (seconds) => {
        // convert total time spent to hours and round to the next 15 minute increment
        var duration = moment.duration(seconds, 'seconds');
        var minutes = Math.ceil(duration.asMinutes() / 15) * 15;
        var decimalMinutes = minutes / 60;
        if (decimalMinutes < 1) {
            return `1 hr`
        }

        return `${decimalMinutes} hrs`
      }
    

  return (
    <div className='timesheetContainer'>
        <div style={{padding: '1rem 0 1rem 7.5vw'}} className='navBar'>
            <img style={Styles.MintLeafLogo} src={MintLeafLogo}/>
        </div>
        <div style={Styles.header}>
        <img style={Styles.accent} src={Accent}/>
            <span style={Styles.timeSheetHeading}>Timesheet</span>
            <span style={Styles.companyTitle}>{timesheetData.client_name}</span>
        </div>

        <hr style={{backgroundColor: '#f4f4f4', display: 'block', height: '0.25rem', border: 'none', margin: '2.5rem 0'}}/>

        <div style={Styles.content}>
            <div className='dateandTime'>
                <div style={{display: 'grid'}}>
            <span style={Styles.total}>{roundedTime(totalTimeSpent)}</span>
            {/* <span style={Styles.timeRounded}>(rounded to next 15 mins)</span> */}
                </div>
            <span style={Styles.date}>{moment(timesheetData.date_created).format('MMM DD, YYYY')}</span>
            </div>
            {timeEntries.map(timeEntry => (
                <div key={timeEntry.id} className='indv-project' style={Styles.projects}>
                <div style={Styles.dateContainer}>
                    <span style={Styles.datebox}>
                        <span>{moment(timeEntry.date).utc().format('MMM')}</span>
                        <span>{moment(timeEntry.date).utc().format('DD')}</span>
                    </span>
                </div>
                <div style={Styles.timeDescriptionContainer}>
                    <div style={{display:'flex', justifyItems: 'start', gap:'1rem'}}>
                        <img style={Styles.stopWatch} src={StopWatch} height="25" width="25"/>
                        {`${timeEntry.hours}:${timeEntry.minutes}:${timeEntry.seconds}`}
                    </div>
                    <div style={{display:'flex', justifyItems: 'start', gap:'1rem'}}>
                        <img style={Styles.descriptionIcon} src={Note} height="25" width="25"/>
                        <p style={{margin:0}}>{timeEntry.description}</p>
                    </div>
                
                </div>
            </div>
            ))}

            <div style={{margin:'5rem 0'}}>
            <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{width:'auto'}}>
                        Terms / Policy
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p style={{color:'#3d3d3d'}}>
                        All work is billed on an hourly basis in 15 minute increments with a minimum invoice time of 1 hour.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>
            </div>
            
        </div>

    </div>
  )
}
const Styles = {
    accent: {
        width: '7.5vw',
        position: 'absolute',
        left: '-7.5vw', 
        top: '-3.5vw',
    },
    MintLeafLogo: {
        width: 'auto',
        height: '2rem'
    },
    header: {
        display: 'grid',
        margin: '0 7.5vw',
        textAlign: 'left',
        marginTop: '5vw',
        position: 'relative',
    },
    timeSheetHeading: {
        fontWeight: "700",
        color: '#FF7DD2',
        fontSize: '1rem',
    },
    companyTitle: {
        fontSize: '4rem',
        fontWeight: '900',
        color: '#3d3d3d',
        lineHeight: '4rem',
    },
    total: {
        fontSize: '2.5rem',
        color: '#40E0A0',
        lineHeight: '2.5rem',
        fontWeight: '900',
    },
    timeRounded: {
        color: '#DFDFDF'
    },
    content: {
        display: 'grid',
        margin: '0 7.5vw',
        fontWeight: '700',
    },    
    projects: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '2rem',
        paddingBottom: '2rem',
    },
    dateContainer: {
        display: "flex",
        backgroundColor: "#f4f4f4",
        width: "5rem",
        height: '5rem',
        minWidth: '5rem',
        textAlign: "center",
        alignItems: "center",
        borderRadius: "4px",
        color: '#3d3d3d',
        justifyContent: "center",
        padding: "0.25rem",
    },
    datebox: {
        fontWeight: "700",
        textTransform: "uppercase",
        letterSpacing: ".075em",
        fontSize: "1.25rem",
        lineHeight: "1.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      timeDescriptionContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '1rem',
        marginLeft: '1rem',
        color: '#3d3d3d',
      }, 
      stopWatch: {
        height: '1.5rem',
        width: 'auto',
        filter: 'invert(100%) sepia(47%) saturate(3119%) hue-rotate(76deg) brightness(98%) contrast(79%)'
},
    descriptionIcon: {
        height: '1.5rem',
        width: 'auto',
        filter: 'invert(72%) sepia(31%) saturate(3049%) hue-rotate(286deg) brightness(104%) contrast(101%)'
      },
        date: {
            fontSize: '1.25rem',
            padding: '0.5rem 1rem',
            borderRadius: '4px',
            backgroundColor: '#f4f4f4',
            color: '#3d3d3d',
            lineHeight: '1.5rem',
            fontWeight: '700',
            textAlign: 'center',
        }
}

export default TimeSheetPage