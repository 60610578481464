import React, {useState} from 'react'
import Nav from './Navigation/Nav'
import SideNav from './SideNav/SideNav';
import TimeEntries from './TimeEntries/TimeEntries'
import { Toaster } from 'react-hot-toast';

const HomePage = () => {

  const [hidePrevious, setHidePrevious] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [activeTimer, setActiveTimer] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [show, setShow] = useState(false);
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

  const timerDropDownToggle = () => {
    setShow(!show)
  }

  return (
    <div>
        <Nav hidePrevious={hidePrevious} showForm={showForm} activeTimer={activeTimer} timerId={timerId} show={show} timerDropDownToggle={timerDropDownToggle} seconds={seconds} minutes={minutes} hours={hours}/>
        <SideNav />
        <Toaster position="bottom-right" toastOptions={{duration:1500}} />
        <TimeEntries passedShowForm={showForm} passedHours={hours} passedMinutes={minutes} passedSeconds={seconds} hidePrevious={hidePrevious} showForm={showForm} passedActiveTimer={activeTimer} passedHidePrevious={hidePrevious} timerId={timerId}/>
    </div>
  )
}

export default HomePage