import React, {useState} from 'react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const ProposalPageModal = ({handleModalShow, rejectProposal, acceptProposal, modalType}) => {
    const [declineHover, setDeclineHover] = useState(false)
    const [acceptHover, setAcceptHover] = useState(false)
    const [signature, setSignature] = useState(null);


  return (
    <div style={Styles.proposalPageModalForm}>
        <h2 style={{fontWeight: '900', fontSize: '2rem', textAlign: 'center', margin: '0', color: '#3d3d3d'}}>{modalType == 'accepted' ? 'Please Type Your Full Name Below' : 'Decline This Proposal?'}</h2>
        {modalType == 'rejected' && <p style={{textAlign: 'center'}}>If you'd like to request edits, please let us know via e-mail. Otherwise, you can decline this proposal below.</p>}
        {modalType == 'accepted' &&
            <div style={{display: 'flex', alignItems:'flex-end', margin: '1.5rem 0',}}>
                <FontAwesomeIcon style={{fontSize: '1.5rem'}} icon={faXmark}/>
                <input style={Styles.signature} onChange={(e) => setSignature(e.target.value)} type="text"/>
            </div>
        }
        <div style={{margin:"1rem 0"}}>
            <button 
                onClick={modalType == 'rejected' ? () => rejectProposal() : () => handleModalShow(null)}
                onMouseEnter={() => setDeclineHover(true)}
                onMouseLeave={() => setDeclineHover(false)}
                style={declineHover ? Styles.declineButtonHovered : Styles.declineButton}>
                    {modalType == 'rejected' ? 'Decline' : 'Go Back'}
            </button>

            <button 
                disabled={signature !== null && signature.length > 0 || modalType == 'rejected' ? false : true}
                onClick={modalType == 'accepted' ? () => acceptProposal(signature) : () => handleModalShow(null)}
                onMouseEnter={() => setAcceptHover(true)}
                onMouseLeave={() => setAcceptHover(false)}
                style={acceptHover ? Styles.acceptButtonHovered : Styles.acceptButton}>
                    {modalType == 'accepted' ? 'Accept' : 'Go Back'}
            </button>
        </div>
    </div>
  )
}

const Styles = {
    proposalPageModalForm: {
        backgroundColor: 'white',
        zIndex: '2',
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        borderRadius: '10px',
        marign:'0 7.5vw',
        padding: '2.5rem',
        width: '75%',
        maxWidth: '750px',
        textAlign: 'center',
    },
    signature: {
        fontSize: '3rem',
        paddingBottom: '0',
        border: 'none',
        borderBottom: 'solid 1px #b1b1b1',
        marginLeft: '1rem',
        outline: 0,
        paddingLeft: '1rem',
        fontFamily: '"Allison", sans-serif',
    },
    declineButton: {
        backgroundColor: '#fff',
        color: '#aaa',
        border: '1px solid #aaa',
        borderRadius: '5px',
        padding: '.5rem 1rem',
        margin: '0 1rem',
        fontSize: '1rem',
        cursor: 'pointer',
        transition: 'all .3s ease-in-out',
    },
    declineButtonHovered: {
        backgroundColor: '#e8e8e8',
        color: '#aaa',
        border: '1px solid #aaa',
        borderRadius: '5px',
        padding: '.5rem 1rem',
        margin: '0 1rem',
        fontSize: '1rem',
        cursor: 'pointer',
        transition: 'all .3s ease-in-out',
    },
    acceptButton: {
        backgroundColor: '#3DE0A0',
        color: 'white',
        border: '1px solid #3DE0A0',
        borderRadius: '5px',
        fontSize: '1rem',
        padding: '.5rem 1rem',
        cursor: 'pointer',
        margin: '0 0.5rem',
        transition: 'all .3s ease-in-out',
    },
    acceptButtonHovered: {
        backgroundColor: 'white',
        color: '#3DE0A0',
        border: '1px solid #3DE0A0',
        borderRadius: '5px',
        fontSize: '1rem',
        padding: '.5rem 1rem',
        cursor: 'pointer',
        margin: '0 0.5rem',
        transition: 'all .3s ease-in-out',
    },
}

export default ProposalPageModal