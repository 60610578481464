import React, {useState, useEffect} from 'react'
import ClientBadge from '../TimerDropdown/ClientBadge';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const ViewAllTimeSheetsBox = ({data, totalTimeSpent}) => {
    const [isHovered, setIsHovered] = useState(false);
    
    const roundedTime = (seconds) => {
        // convert total time spent to hours and round to the next 15 minute increment
        var duration = moment.duration(seconds, 'seconds');
        var minutes = Math.ceil(duration.asMinutes() / 15) * 15;
        var decimalMinutes = minutes / 60;
        if (decimalMinutes < 1) {
            return `1 hr`
        }
        
        return `${decimalMinutes} hrs`
    }
    const roundedStoredTime = roundedTime(totalTimeSpent)
    const totalMonies = parseFloat(roundedStoredTime) * 65;

  return (
          <button
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
           style={isHovered ? Styles.boxHovered : Styles.box}
           >
              <span style={Styles.statusSpan}>{data.status}</span>
              <span style={Styles.timeSpan}>{roundedStoredTime}</span>
              <ClientBadge style={Styles.allTimeSheetsBadge} data={data}></ClientBadge>
              <span style={Styles.timeEntryDescription}>$ {totalMonies}</span>
              <Link to={`${data.id}`} target='_blank' >
              <FontAwesomeIcon style={Styles.linkIcon} icon={faArrowUpRightFromSquare} />
              </Link>
          </button>
  )
}

const Styles = {
    box: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        justifyItems: 'start',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#6b705c',
        color: '#f0efeb',
        border: '1px solid transparent',
        borderRadius: '4px',
        width: '100%',
        padding: '1rem',
        gridGap: '1rem',
        margin: ' 0 0 1rem 0'
    },
    boxHovered: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        justifyItems: 'start',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#b7b7a4',
        color: '#f0efeb',
        border: '1px solid #ddb892',
        borderRadius: '4px',
        width: '100%',
        padding: '1rem',
        gridGap: '1rem',
        margin: ' 0 0 1rem 0'

    },
    statusSpan: {
        color: '#faedcd',
        backgroundColor: '#ccd5ae',
        width: 'auto',
        alignItems: 'center',
        display: 'flex',
        textAlign: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem 1rem',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    timeSpan: {
        color: '#f0efeb',
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem 1rem',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 'bold'

    },
    allTimeSheetsBadge: {
        fontSize: '1rem',
    },
    timeEntryDescription: {
        color: '#f0efeb',
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem 1rem',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    editButton: {
        backgroundColor: '#0f0f0f',
        borderRadius: '4px',
        color: 'lightgreen',
        border: '1px solid white',
        display:'flex',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        marginRight: '1rem',
        width: '2vw'
    },
    modalContainer: {
        display: 'grid',
        position: 'fixed',
        top: '0',
        left:'0',
        width: '100%',
        height: '100%',
        justifyItems: 'center',
        alignItems: 'center',
      },
    modalOverlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
        width:"100%",
        height:"100vh",
        position: "fixed",
        top:"0",
        left:"0",
      },
      linkIcon: {
          color: '#f0efeb',
            fontSize: '1rem',
            fontWeight: 'bold',
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            justifyItems: 'center',
            justifyContent: 'center',
            padding: '0.5rem 1rem',
            borderRadius: '4px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginLeft: '1rem'

      }
}

export default ViewAllTimeSheetsBox