import './App.css';
import { AuthProvider } from 'react-auth-kit'
import AppRoutes from './AppRoutes';
import refreshApi from "./refreshApi";

function App() {

  return (
  <AuthProvider authType = {'localstorage'}
                authName={'_auth'}
                cookieDomain={'data.mintleafmarketing.com'}
                cookieSecure={false}
                refresh={refreshApi}>
        <AppRoutes/>
    </AuthProvider>
  );
}

export default App;
