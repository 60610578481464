import {React, useEffect, useContext, useState} from "react";
import DatePicker from "react-datepicker";
import ClientDropdown from './ClientDropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import './DatePicker.css';
import axios from 'axios';
import moment from 'moment';
import {useAuthHeader} from 'react-auth-kit'
import {TimerContext} from '../../../contexts/TimerContext'
import {updateData} from '../../../helpers/api'

const Form = ({isRunning, handleBackChange, deleteProject}) => {
    const {currentTimer, setCurrentTimer} = useContext(TimerContext);
    const [startDate, setStartDate] = useState(currentTimer == null || currentTimer.start_date == null ? moment().toDate() : moment(currentTimer.start_date).toDate());
    const [rate, setRate] = useState(currentTimer == null || currentTimer.rate == null ? '' : currentTimer.rate);
    const [description, setDescription] = useState(currentTimer == null || currentTimer.description == null ? '' : currentTimer.description);
    const [isVisible, setIsVisible] = useState(true);
    const authHeader = useAuthHeader();


    useEffect(() => {        
        if (isRunning) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
       
    }, [isRunning])


      const handleDateChange = (date) => {
        setStartDate((date));
        updateData(currentTimer.id, authHeader(), {date: moment(date).format('YYYY-MM-DD')})
        setCurrentTimer({...currentTimer, date: date});
        console.log(moment(date).format('YYYY-MM-DD'));
    }

    const handleSubmit = event => {
        event.preventDefault();
    };

    /* Rate Update */
    useEffect(() => {
        // only run if rate is different from current rate
        if (currentTimer !== null && rate != currentTimer.rate && !isNaN(rate)) {
        setCurrentTimer({...currentTimer, rate: rate});
        const delayDebounceFn = setTimeout(() => {
          updateData(currentTimer.id, authHeader(), {rate: rate})
        }, 500)
    
        return () => clearTimeout(delayDebounceFn)
        }
      }, [rate])


    /* Description Update */
    useEffect(() => {
        // only run if description is different from current description
        if (currentTimer !== null && description != currentTimer.description && description !== '') {
        setCurrentTimer({...currentTimer, description: description});
        const delayDebounceFn = setTimeout(() => {
          updateData(currentTimer.id, authHeader(), {description: description})
        }, 500)
    
        return () => clearTimeout(delayDebounceFn)
        }
      }, [description])


    return (       
        <div>
            <form onSubmit={handleSubmit} style={Styles.form}>
                <div style={Styles.formGroup}>
                    <label style={Styles.label} htmlFor="client">Client:</label>
                     <ClientDropdown/>
                </div>

                <div style={Styles.splitContainer}>
                    <div style={Styles.formGroup}>
                        <label style={Styles.label}>Date:</label>
                        <DatePicker
                        placeholderText="Click to select a date"
                        onChange={date => handleDateChange(date)}
                        selected={startDate}
                        closeOnScroll={true}
                        />
                    </div>
                    
                    <div style={{...Styles.formGroup, ...Styles.rateBounding}}>
                        <label style={Styles.label}>Rate:</label>
                        <div style={Styles.rateContainer}>
                        <span style={Styles.rateBadge}>$</span>
                        <input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} style={Styles.badgedInput} type="text" placeholder="85" onChange={(e) => setRate(e.target.value)} value={currentTimer?.rate}></input>
                        </div>
                    </div>
                </div>

                <div style={Styles.formGroup}>
                    <label style={Styles.label}>Description:</label>
                    <textarea type="text" style={{...Styles.input, ...Styles.textArea}} onChange={(e) => setDescription(e.target.value)} placeholder="What are you working on?" name="description" rows="3" cols="25" defaultValue={currentTimer?.description}></textarea>
               </div>

               
                <div style={Styles.iconContainer}>
                    <button style={isVisible ? Styles.bottomBarButton : Styles.hiddenBar} onClick={handleBackChange}>
                        <FontAwesomeIcon style={Styles.backArrow} alt='previous page' icon={faAngleLeft}/>
                    </button>
                    <button type="button" style={isVisible ? Styles.bottomBarButton : Styles.hiddenBar} onClick={deleteProject}>
                        <FontAwesomeIcon style={Styles.trashCan} className='trash-can-icon' alt='delete' icon={faTrashCan}/>    
                    </button>
                </div>
                 

            </form>
        </div>
    );
}

const Styles = {
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    splitContainer: {
        display: 'flex',
        gap: '1rem',
    },
    input: {
        backgroundColor: '#ede0d4',
        border: 'solid 1px #BFBFBF',
        borderRadius: '5px',
        padding: '1rem',
        fontSize: '1rem',
        color: '#6b705c',
    },
    label: {
        fontSize: '1rem',
        color: '#6b705c',
        fontWeight: 'bold',
        marginBottom: '0.5rem',
        fontFamily: 'Kanit',
    },
    form: {
        display: 'grid',
        gap: '1rem',
    },
    rateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        margin: '0 auto',
        
    },
    rateBadge: {
        fontSize: '1.25rem',
        color: '#ede0d4',
        fontWeight: 'bold',
        backgroundColor: '#6b705c',
        border: 'solid 1px #6b705c',
        height: 'calc(100% - 2px)',
        padding: '0 0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px 0px 0px 5px',
    },
    badgedInput: {
        borderRadius: '0px 5px 5px 0px',
        backgroundColor: '#ede0d4',
        border: 'solid 1px #BFBFBF',
        padding: '1rem',
        fontSize: '1rem',
        color: '#6b705c',
        width: '100%',
    },
    rateBounding: {
        maxWidth: '35%',
    },
    textArea: {
        resize: 'none',
        width: 'calc(100% - 2rem)',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    backArrow: {
        color: '#6b705c'
    },
    trashCan: {
        color: '#ff7675',
    },
    bottomBarButton: {
        cursor: 'pointer',
        backgroundColor: '#C0BEAB',
        padding: '0.5rem 1rem',
        borderRadius: '5px',
        border: 'none',
        display: 'flex',
        height: 'auto',
        overflow: 'unset',
        transition: '0.5s all ease-in-out',
    },
    hiddenBar: {
        height: 0,
        overflow: 'hidden',
        transition: '0.5s all ease-in-out',
        background: 'transparent',
        border: 'none',
        padding: 0,
    },
};

export default Form;