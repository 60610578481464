import React from "react";
import TimeInput from "./TimeInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'

const Styles = {
  button: {
    backgroundColor: "#3ed927",
    color: 'white',
    borderRadius: '5px'
  },
  timeBlock: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    fontSize: '2rem',
    alignItems: 'center',
    display: 'inline-block',
  },
  start: {
    color: '#fff',
    backgroundColor: '#28F57F',
    borderRadius: '2rem',
    height: '3rem',
    width: '3rem',
    padding: '0',
    transition: 'all 0.2s ease-in-out',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pause: {
    color: '#fff',
    backgroundColor: '#F52960',
    borderRadius: '2rem',
    height: '3rem',
    width: '3rem',
    padding: '0',
    transition: 'all 0.2s ease-in-out',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#6b705c",
    marginBottom: "1rem",
    width: "calc(100% + 2rem)",
    padding: "1rem",
    borderRadius: "5px",
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
  },
  startNewTimerText: {
    color: 'gray',
    fontSize: '.75em'
  }
}

const Timer = ({isRunning, hours, minutes, seconds, handleToggleClick, handleSecondsChange, handleMinutesChange, handleHoursChange, handleFocusLost, activeTimer}) => {
  
  return (
    <div style={Styles.timerContainer}>
       {/* <span style={Styles.startNewTimerText}>START NEW TIMER</span> */}
      <div style={Styles.timeBlock}>
        <div style={Styles.inputContainer}>
        <TimeInput handleFocusLost={handleFocusLost} handleSecondsChange={handleSecondsChange} handleMinutesChange={handleMinutesChange} handleHoursChange={handleHoursChange} isRunning={isRunning} entryHours={hours} entryMinutes={minutes} entrySeconds={seconds} activeTimer={activeTimer}/>
        </div>
        <button onClick={() => handleToggleClick()} style={isRunning ? Styles.pause : Styles.start}>
          {isRunning ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay}/>}
        </button>
      </div>
    </div>
  )
};



export default Timer;