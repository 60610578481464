import axios from 'axios'
import {useAuthHeader, createRefresh} from 'react-auth-kit'

const refreshApi = createRefresh({
  interval: 5,   // Refreshes the token in every 10 minutes
  refreshApiCallback: ({authToken, authTokenExpireAt, refreshToken, refreshTokenExpiresAt, authUserState}) => {
      return axios.post('https://data.mintleafmarketing.com/auth/refresh', {
        refresh_token: refreshToken,
        withCredentials: true
      }).then(({data}) => {
        console.log(data);
      return ({
        isSuccess: true,  // For successful network request isSuccess is true
        newAuthToken: data.data.access_token,
        newAuthTokenExpireIn: (data.data.expires / 60000),
        newRefreshToken: data.data.refresh_token,
        newRefreshTokenExpiresIn: (data.data.expires / 60000) + 10080,
        newAuthUserState: authUserState
        //You can also add new refresh token ad new user state
      })
    }).catch((e)=>{
      console.error(e)
      return {
        isSuccess:false // For unsuccessful network request isSuccess is false
      }
    })
  }
})

export default refreshApi