import React, {useContext, useState} from 'react';
import TimerDropper from '../TimerDropdown/TimerDropper';
import { Link } from "react-router-dom";
import MintleafLogo from "../../../svg/mintLeafLogo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
// import ToggleSwitch from './ToggleSwitch/ToggleSwitch';

const Styles = {
  navContainer: {
    backgroundColor: '#6b705c',
    position: 'fixed',
    zIndex: '100',
    display: 'flex',
    height: '50px',
    top: '0',
    right: '0',
    filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.15))',
    justifyContent: 'space-between',
    width: '85%',
  },
  navbarLeft: {
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',
    alignItems: 'center',
    color: '#eee'
  },
  navbarRight: {
    display: 'flex',
  }, 
  themeDiv: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem'
  },
  sun: {
    color: 'white'
  }
}

const Nav = ({hidePrevious, showForm, show, activeTimer, timerId, timerDropDownToggle, seconds, minutes, hours}) => {
  const [isHovered, setIsHovered] = useState(false)


  return (
    <div style={Styles.navContainer}>
        <div style={Styles.navbarLeft}>
            <Link to="/dashboard"><img style={{height:'25px', marginLeft:'7.5vw'}} src={MintleafLogo}/></Link>
        </div>
        {/* <div>
          <div style={Styles.themeDiv}>
            <FontAwesomeIcon style={Styles.sun} icon={faSun}/>
                  <ToggleSwitch/>
            <FontAwesomeIcon icon={faMoon}/>
          </div>
        </div> */}
        <div style={Styles.navbarRight}>
            <TimerDropper passedSeconds={seconds} passedMinutes={minutes} passedHours={hours} show={show} hidePrevious={hidePrevious} showForm={showForm} activeTimer={activeTimer} timerId={timerId} timerDropDownToggle={timerDropDownToggle}/>
            <div>
           
            </div>
          <div>
          </div>
        </div>
    </div>
  )
}

export default Nav;