import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import {useIsAuthenticated, useSignIn, useSignOut} from 'react-auth-kit';
import MintLeafLogo from '../../svg/mintLeafLogo.svg'

const LoginPage = () => {

    const signIn = useSignIn()
    const signOut = useSignOut();
    let { logout } = useParams();
    const [formData, setFormData] = useState({email: '', password: ''})
    const [isHovered, setIsHovered] = useState(false)

    const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if(logout) {
            signOut();
        }
        if (isAuthenticated()) {
            navigate('/dashboard')
        }
    }, [isAuthenticated, navigate])

    const onSubmit = (e) => {
        e.preventDefault()
        axios.post('https://data.mintleafmarketing.com/auth/login', formData)
            .then((res)=>{
                if(res.status === 200){
                    getUserInfo(res.data.data.access_token).then(data => {
                        if(signIn({token: res.data.data.access_token,
                                   expiresIn:(res.data.data.expires / 60000),
                                   tokenType: "Bearer",
                                   authState: data,
                                   refreshToken: res.data.data.refresh_token,
                                   refreshTokenExpireIn: (res.data.data.expires / 60000) + 10080,}))
                        {
                            navigate('/dashboard');
                        } else {
                            //Throw error
                            console.log("Error");
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }
            }).catch((err)=>{
                console.log(err);
            });
    }

    const getUserInfo = async (bearerToken) => {

        var config = {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${bearerToken}`
            },
            url: `https://data.mintleafmarketing.com/users/me?fields=*.*`,
        };

        try {
            const {data:response} = await axios(config)
            return response.data
          }
            
          catch (error) {
            console.log(error);
          }
    }


    return (
        <form style={Styles.logInForm} onSubmit={onSubmit}>
            <div style={Styles.logInFormBackground}>

                <div style={Styles.logInContainer}>
                    <img style={Styles.logo} src={MintLeafLogo}/>
                    <input style={Styles.fieldInputs} type={"email"} onChange={(e)=>setFormData({...formData, email: e.target.value})}/>
                    <input style={Styles.fieldInputs} type={"password"} onChange={(e)=>setFormData({...formData, password: e.target.value})}/>
                    <button 
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={isHovered ? Styles.logInButtonHovered : Styles.logInButton}>Submit</button>
                </div>
            </div>
        </form>
    )
}

const Styles = {
    logInForm: {
        display: 'grid',
        position: 'fixed',
        top: '0',
        left:'0',
        width: '100%',
        height: '100%',
        justifyItems: 'center',
        alignItems: 'center',
    },
    logInFormBackground: {
        backgroundColor: '#6b705c',
        position: 'fixed',
        width: '50%',
        height: '50%',
        justifyItems: 'center',
        alignItems: 'center',
        display: 'grid',
        borderRadius: '10px',
    },
    logInContainer: {
        display: 'grid',
        justifyItems: 'center',
        gap: '1rem'
    },
    logo: {
        width: '100%',
        height: '100%', 
        objectFit: 'contain',   
        marginBottom: '1rem'
    },
    logInButton: {
        backgroundColor: '#a5a58d',
        borderRadius: '4px',
        color: '#f0efeb',
        border: '1px solid transparent',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        fontWeight: '700',
        width: '8rem',
        lineHeight: '1rem',
      },
      logInButtonHovered: {
        backgroundColor: '#C0BEAB',
        borderRadius: '4px',
        color: '#f0efeb',
        border: '1px solid white',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        fontWeight: '700',
        width: '8rem',
        lineHeight: '1rem'
      },
      fieldInputs: {
        borderRadius: '4px',
        backgroundColor: '#ede0d4',
        color: '#6b705c'
      }
}

export default LoginPage;