import React from 'react';

const Styles = {
    clientNameDiv: {
        display: 'block',
        whiteSpace: 'nowrap',
        backgroundColor: '#a5a58d',
        padding: '0.25rem 0.5rem',
        borderRadius: '5px',
      },
      clientNameSpan: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        maxWidth: '12vw',
        whiteSpace: 'nowrap',
        color: '#eee'
      },
      clientNameSpanNone: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        maxWidth: '12vw',
        whiteSpace: 'nowrap',
        color: '#ff7675',
      }
    }

const ClientBadge = (data) => {

  return (
      <div style={Styles.clientNameDiv}>
          <span style={data.data.client_name ? Styles.clientNameSpan : Styles.clientNameSpanNone}>{data.data.client_name ? data.data.client_name : 'No Client Selected'}</span>
      </div>
    )
}

export default ClientBadge