import React, {useEffect, useState, useRef, useContext} from 'react'
import axios from 'axios'
import TimeEntiresBox from './TimeEntiresBox'
import TimeSheetModal from '../TimeEntries/TimeSheetModal'
import {useAuthHeader, useAuthUser} from 'react-auth-kit'
import TimeEntriesModal from './TimeEntriesModal'
import AngryChinaMom from '../../../img/real_tax_documents.png'
import Spinner from '../../Spinner'
import toast from 'react-hot-toast';
import { fetchEntryData, deleteProject } from '../../../helpers/api'
import Moment from 'moment'
import {TimerContext} from '../../../contexts/TimerContext'

const TimeEntries = ({loadTimer, passedSeconds, passedMinutes, passedHours, passedActiveTimer, passedHidePrevious, passedShowForm}) => {
  const {currentTimer, setCurrentTimer} = useContext(TimerContext);
  const [isHovered, setIsHovered] = useState(false);
  const [seconds, setSeconds] = useState(passedSeconds);
  const [minutes, setMinutes] = useState(passedMinutes);
  const [hours, setHours] = useState(passedHours);
  const [hidePrevious, setHidePrevious] = useState(passedHidePrevious);
  const [timeEntries, setTimeEntries] = useState([]);
  const [activeTimer, setActiveTimer] = useState(passedActiveTimer);
  const [showForm, setShowForm] = useState(passedShowForm);
  const [openModal, setOpen] = useState(false);
  const [entriesTimerId, setTimerId] = useState()
  const authHeader = useAuthHeader();
  const [entriesModal, setEntriesModal] = useState(false)
  const [modalData, setModalData] = useState([])
  const sortedDate = timeEntries.sort((a, b) => new Moment(b.date_updated) - new Moment(a.date_updated) )
  const ref = useRef(null)

  const handleEntriesModalShow = (data) => {
    setEntriesModal(!entriesModal);
    setModalData(data)
  }

const handleEntriesModalHide = () => {
    setEntriesModal(false)
}
  const user = useAuthUser();
  const callURL = 'https://data.mintleafmarketing.com/items/time_entry?filter[status]=draft&fields=*.*&filter[team]=' + user().team.id;
  const [loading, setLoading] = useState(true);

  const deleteClick = () => {
    deleteProject( modalData.id, authHeader()).then(data => {
      setEntriesModal(false)
      setHours('00')
      setMinutes('00')
      setSeconds('00')
      setHidePrevious(true)
      setActiveTimer(false)
      setShowForm(false)
      setCurrentTimer(null)
      fetchEntryData(authHeader()).then(data => {
        setTimeEntries(data)
        setTimerId(data.id)
        setLoading(false);

      }).catch(err => {
          console.log(err);
      });
    }).catch(error => {
      console.log(error)
    }); 
  }
  useEffect(() => {
      setInterval(() => {
        fetchEntryData(authHeader()).then(data => { 
          setTimeEntries(data)
          setTimerId(data.id)
          setLoading(false);
        }).catch(err => {
            console.log(err);
        }
        );
      }, 15000);
  }, [])

    useEffect(() => {
        // axios request to get all "draft" time entries
        fetchEntryData(authHeader()).then(data => {
          setTimeEntries(data)
          setTimerId(data.id)
          setLoading(false);
        }).catch(err => {
            console.log(err);
        });

        const handleKeyPressClose = (event) => {
          if(event.code === 'Escape') {
              setEntriesModal(false)
          }
      }
      document.addEventListener('keydown', handleKeyPressClose)
      return () => document.removeEventListener('keydown', handleKeyPressClose)
    }, []);

    const handleShow = () => {
      setOpen(!openModal)
    }


  return (
    <div style={Styles.timeEntryContainer}>
      <button 
      onClick={handleShow}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={isHovered ? Styles.createInvoiceButtonHovered : Styles.createInvoiceButton}>
        Create Timesheet
      </button>
      {openModal && 
      <div style={Styles.modalContainer}>
        <TimeSheetModal handleShow={handleShow}/> 
        <div style={Styles.modalOverlay} onClick={() => setOpen(false)} ></div>
      </div>
      }
      { !loading ?
      <div style={Styles.timeEntriesBoxContainer}>
        {typeof(sortedDate) !== 'undefined' && sortedDate.length > 0 ? sortedDate.map((timeEntry) => {
          return (
            <TimeEntiresBox
            // handleEntriesModalShow={handleEntriesModalShow}
            loadTimer={loadTimer}
            data={timeEntry}
            key={timeEntry.id}
            />
            )
          }
          ) : <div style={Styles.noEntries}>
            <p>No entries...? Why you no do your work!?</p>
            <img style={{width:'100%', maxWidth:'450px'}} src={AngryChinaMom} />
          </div>}
      </div> : <div><Spinner/></div>}
      {/* {entriesModal &&
            <div style={Styles.modalContainer}> 
              <TimeEntriesModal deleteClick={deleteClick} data={modalData} timerId={modalData.id}
              entriesModal={entriesModal} handleEntriesModalHide={handleEntriesModalHide}/>
              <div 
              onClick={() => handleEntriesModalHide()}
              style={Styles.modalOverlay}
              ></div>
            </div>
          } */}
        </div>)}

const Styles = {
  timeEntryContainer: {
    display: 'grid',
    color: 'white',
    marginTop: '50px',
    justifyContent: 'center',
    marginLeft: '15%'
  },
  noEntries: {
    color: '#3d3d3d',
    backgroundColor: '#d3d3d3',
    padding: '16px',
    borderRadius: '5px',
  },
  createInvoiceButton: {
    backgroundColor: '#6b705c',
    borderRadius: '4px',
    color: '#f0efeb',
    border: '1px solid transparent',
    display:'flex',
    justifyContent: 'space-around',
    cursor: 'pointer',
    alignSelf: 'center',
    width: 'max-content',
    margin: '1rem 0.5rem',
    padding: '0.5rem 1rem',
  },
  createInvoiceButtonHovered: {
    backgroundColor: '#C0BEAB',
    borderRadius: '4px',
    color: '#f0efeb',
    border: '1px solid #ddb892',
    display:'flex',
    justifyContent: 'space-around',
    cursor: 'pointer',
    alignSelf: 'center',
    width: 'max-content',
    margin: '1rem 0.5rem',
    padding: '0.5rem 1rem',
  },
  timeEntriesBoxContainer: {
    display: 'grid',
    width: '70vw',
    gap: '.5rem',
    padding: '0px 5px',
    borderRadius: '3px',
    padding: '.5rem'
  },
  modalContainer: {
    display: 'grid',
    position: 'fixed',
    top: '0',
    left:'0',
    width: '100%',
    height: '100%',
    justifyItems: 'center',
    alignItems: 'center',
  },
  modalOverlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    width:"100%",
    height:"100vh",
    position: "fixed",
    top:"0",
    left:"0",
  }
}

export default TimeEntries