import React, {useEffect, useState} from 'react';
import ClientDropDown from '../TimerDropdown/ClientDropdown';
import axios from 'axios';
import DatePicker from "react-datepicker";
import {useAuthHeader} from 'react-auth-kit';
import moment from 'moment';
import { updateDate, updateData } from '../../../helpers/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

    const TimeEntriesModal = ({data, deleteClick, handleEntriesModalHide}) => {
        const [deleteIsHovered, setDeleteIsHovered] = useState(false);
        const [seconds, setSeconds] = useState(data.seconds);
        const [minutes, setMinutes] = useState(data.minutes);
        const [hours, setHours] = useState(data.hours)
        const [rate, setRate] = useState(data.rate)
        const [description, setDescription] = useState(data.description)
        const [startDate, setStartDate] = useState(moment(data.date).toDate())
        const [lastUpdatedBy, setLastUpdatedBy] = useState(data.user_updated)

        const authHeader = useAuthHeader();

        useEffect(() => {
            // only run if rate is different from current rate
            if (!isNaN(rate)) {
                const delayDebounceFn = setTimeout(() => {
                updateData(data.id, authHeader(), {rate: rate})
                }, 500)
                return () => clearTimeout(delayDebounceFn)
            }    
        }, [rate])

        useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
            updateData(data.id, authHeader(), {description: description})
            }, 500)
        
            return () => clearTimeout(delayDebounceFn)
        }, [description])

        useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
            updateData(data.id, authHeader(), {seconds: seconds, minutes: minutes, hours: hours})
            }, 500)
        
            return () => clearTimeout(delayDebounceFn)
        }, [seconds, minutes, hours])

        
        
        const handleDateChange = (date) => {
            setStartDate(moment(date).toDate());
            updateDate(moment(date).toDate(), data.id, authHeader());
        }


  return (
    <div style={Styles.modalContainer}>
        <div style={Styles.headerContainer}>
            <div style={Styles.xButtonHeader}>
                <span style={Styles.headerSpan}>Update Time</span>
                <button onClick={handleEntriesModalHide} style={Styles.xButton}>
                <FontAwesomeIcon icon={faXmark} style={Styles.xMark} />
                </button>
            </div>
            <hr></hr>
        </div>
        <div style={Styles.clientDiv}>
            <label>
                Client
            </label>
            <ClientDropDown/>
        <hr style={Styles.HR}></hr>
        </div>
        <div style={Styles.mainContent}>
            <div style={Styles.RateNoteContainer}>
                <label>Rate</label>
                <input type="text" maxLength='4' style={Styles.noteInput} value={rate} onChange={(e) => setRate(e.target.value)}></input>
                <label>Notes</label>
                <textarea type="text-area" style={Styles.noteInput} onChange={(e) => setDescription(e.target.value)}>{description}</textarea>
            </div>
            <div style={Styles.timeDateContainer}>
                <label>Time & Date</label>
                    <div style={Styles.timeContainer}>
                        <div style={Styles.timers}>
                            <label style={Styles.timeLabel}>HR</label>
                            <input style={Styles.timeInput} size="2" min="0" max="99" maxLength="2" placeholder="00" type='text' onChange={(e) => setHours(e.target.value)} value={hours}></input>
                        </div>
                        <div style={Styles.timers}>
                            <label style={Styles.timeLabel}>MIN</label>
                            <input style={Styles.timeInput} size="2" min="0" max="99" maxLength="2" placeholder="00" type='text' onChange={(e) => setMinutes(e.target.validationMessage)} value={minutes}></input>
                        </div>
                        <div style={Styles.timers}>
                            <label style={Styles.timeLabel}>SEC</label>
                            <input style={Styles.timeInput} size="2" min="0" max="99" maxLength="2" placeholder="00" type='text' onChange={(e) => setSeconds(e.target.value)}value={seconds}></input>
                        </div>
            
                </div>
                <div style={Styles.datePickerContainer}>
                    <DatePicker
                        placeholderText="Click to select a date"
                        selected={startDate}
                        onChange={date => handleDateChange(date)}
                        />
                </div>
               <div style={Styles.lastUpdatedContainer}>
                   <span>Last Updated By</span>
                   <span>{lastUpdatedBy.first_name}</span>
               </div>
            </div>
        </div>
                <div style={Styles.UpdateContainer}>
                    <button 
                    onClick={deleteClick}
                    onMouseEnter={() => setDeleteIsHovered(true)}
                    onMouseLeave={() => setDeleteIsHovered(false)}
                    style={deleteIsHovered ? Styles.deleteButtonHovered : Styles.deleteButton}>
                        Delete Draft
                    </button>

                </div>
    </div>
  )
}

const Styles = {
    modalContainer: {
        display: 'grid',
        width: '70vw',
        maxWidth: '600px',
        alignItems: 'center',
        border: '1px solid white',
        color: 'white',
        gap: '1rem',
        padding: '.5rem',
        backgroundColor: 'rgb(183, 183, 164)',
        position:"fixed",
        zIndex: '10',
        padding: '1rem',
        height: '80vh',
        gridAutoRows: 'max-content'
    },
    headerContainer: {
        fontSize: '1.5rem'
    },
    xButtonHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    xButton: {
        backgroundColor: 'rgb(192, 190, 171)',
        color: 'rgb(107, 112, 92)',
        border: '.5px solid rgb(107, 112, 92)',
        borderRadius: '4px',
    },
    headerSpan: {
        display: 'grid',
        justifyItems: 'center',
        fontWeight: '700',
        fontSize: '1.5rem'
    },
    xMark: {
        cursor: 'pointer',
    },
    clientDiv: {
        display: 'grid'
    },
    HR: {
        width: '100%',
    },
    mainContent: {
        display: 'flex',
        justifyContent: 'space-around',
        gap: '1rem',
        padding: '1rem'
    },
    RateNoteContainer: {
        display: 'grid',
        width: '100%',
        gridTemplateRows: '1fr 1fr 1fr 3fr'
    },
    noteInput: {
        borderRadius: '4px',
        backgroundColor: '#ede0d4',
        color: '#6b705c'
    },
    timeDateContainer: {
        display: 'grid',
        width: '100%'
    },
    timeContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '1rem 0',
        
    },
    timers: {
        display: 'grid',
        textAlign: 'center',
        color: 'gray',
        gridTemplateRows: '1fr 2fr',
        margin: '0 1rem 0 0',
        borderRadius: '4px',
        backgroundColor: '#ede0d4',
        color: '#6b705c'
    },
    timeLabel: {
        alignSelf: 'center',
        backgroundColor: '#ede0d4',
        color: '#6b705c'
    },
    timeInput: {
        maxWidth: '90px',
        lineHeight: '1.5rem',
        border: '1px solid transparent',
        WebkitAppearence: 'none',
        MozAppearance: '0',
        margin: '0',
        fontSize: '2rem',
        textAlign: 'center',
        borderRadius: '4px',
        backgroundColor: '#ede0d4',
        color: '#6b705c'
    },
    UpdateContainer: {
        display: 'flex',
        justifyContent: "space-between",
        padding: '1rem',
        margin: '1rem'
    },
    deleteButton: {
        backgroundColor: 'white',
        borderRadius: '4px',
        color: 'red',
        border: '1px solid red',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        marginRight: '1rem',
        fontWeight: '700',
        width: '8rem',
        height: '2.5rem',
        lineHeight: '1rem'
    },
    deleteButtonHovered: {
        backgroundColor: 'red',
        borderRadius: '4px',
        color: 'white',
        border: '1px solid white',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        marginRight: '1rem',
        fontWeight: '700',
        width: '8rem',
        height: '2.5rem',
        lineHeight: '1rem'
    },
    updateButton: {
        backgroundColor: '#a5a58d',
        borderRadius: '4px',
        color: '#f0efeb',
        border: '1px solid white',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        marginRight: '1rem',
        fontWeight: '700',
        width: '8rem',
        height: '2.5rem',
        lineHeight: '1rem'
    },
    updateButtonHovered: {
        backgroundColor: '#adc178',
        borderRadius: '4px',
        color: '#f0efeb',
        border: '1px solid white',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        alignSelf: 'center',
        marginRight: '1rem',
        fontWeight: '700',
        width: '8rem',
        height: '2.5rem',
        lineHeight: '1rem'
    },
    lastUpdatedContainer: {
        display: "grid",
        justifyItems: 'end',
        fontSize: '.9rem',
    }
}

export default TimeEntriesModal