import React, { useEffect, useContext, useState } from 'react';

const Styles = {
  timeInputEnabled: {
    border: "none",
    fontSize: "2.5rem",
    color: "#eee",
    backgroundColor: '#6b705c',
    padding: '0',
    textAlign:'center',
    borderBottom: '2px solid #29F57F',
    transition: 'border-bottom 0.25s ease-in-out',
    fontWeight: 'bold',
    fontFamily: 'IBM Plex Mono, monospace',
  },
  semiSpanDisabled: {
    display: 'inline-block',
    fontSize: '2.5rem',
    color: '#eee',
  },
  semiSpanEnabled: {
    display: 'inline-block',
    fontSize: '2.5rem',
    color: '#9d9d9d',
  },
  timeInputDisabled: {
    border: "none",
    fontSize: "2.5rem",
    color: "#9d9d9d",
    backgroundColor: '#6b705c',
    padding: '0',
    textAlign:'center',
    borderBottom: '2px solid transparent',
    transition: 'border-bottom 0.25s ease-in-out',
    fontWeight: 'bold',
    fontFamily: 'IBM Plex Mono, monospace',
  },
}
const TimeInput = ({isRunning, entryHours, entryMinutes, entrySeconds, handleSecondsChange, handleMinutesChange, handleHoursChange, handleFocusLost, activeTimer}) => {

  const handleFocus = (event) => event.target.select();


  return (
    <>
      <input style={isRunning || !activeTimer ? Styles.timeInputDisabled : Styles.timeInputEnabled} type="text" size="2" min="0" max="99" maxLength="2" timetype="hours" disabled={isRunning || !activeTimer} placeholder="00" value={entryHours} onBlur={handleFocusLost} onFocus={handleFocus} onChange={handleHoursChange}/>

      <span style={!isRunning || !activeTimer ? Styles.semiSpanDisabled : Styles.semiSpanEnabled}>:</span>

      <input style={isRunning || !activeTimer ? Styles.timeInputDisabled : Styles.timeInputEnabled} type="text" size="2" min="0" max="59" maxLength="2" timetype="minutes" disabled={isRunning || !activeTimer} placeholder="00" value={entryMinutes} onBlur={handleFocusLost} onFocus={handleFocus} onChange={handleMinutesChange}/>

      <span style={!isRunning || !activeTimer ? Styles.semiSpanDisabled : Styles.semiSpanEnabled}>:</span>

      <input style={isRunning || !activeTimer ? Styles.timeInputDisabled : Styles.timeInputEnabled} type="text" size="2" min="0" max="59" maxLength="2" timetype="seconds" disabled={isRunning || !activeTimer} placeholder="00" value={entrySeconds} onBlur={handleFocusLost} onFocus={handleFocus} onChange={handleSecondsChange}/>
    </>
  )
}

export default TimeInput;