import React, { useState, useEffect } from "react";
import axios from "axios";
import Accent from "../../svg/accent.svg";
import MintLeafLogo from "../../svg/mintLeafLogo.svg";
import "../TimeSheetsPage/TimeSheetPage.css";
import "../Proposals/ProposalPage.css";
import { useParams } from "react-router-dom";
import "react-accessible-accordion/dist/fancy-example.css";
import ProposalPageModal from "./ProposalPageModal";
import moment from "moment";
import { Toaster, toast } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProposalPage = () => {
  const { proposalId } = useParams();
  const [proposalData, setProposalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [declineHover, setDeclineHover] = useState(false);
  const [acceptHover, setAcceptHover] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [proposalStatus, setProposalStatus] = useState();
  const rejectedStatus = "rejected";
  const [proposal_id, setProposalId] = useState(null);
  const acceptRejectDate = moment().format();
  const [modalType, setModalType] = useState(null);
  const [totalPriceAdded, setTotalPrice] = useState([]);
  const [proposalARDate, setProposalARDate] = useState(null);
  const [isDraft, setIsDraft] = useState(false);

  const proposalResponse = async (proposalId, status, date, signature) => {
    axios({
      method: "patch",
      url: `https://data.mintleafmarketing.com/items/proposals/${proposalId}`,
      headers: {
        Authorization: `Bearer 3db0f55f-ed56-4c51-84d0-5870a9aea4be`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
        accept_reject_date: date,
        signed_by: signature || "",
      },
    })
      .then((response) => {
        //    console.log(response.data.data)
        if (response.data.data.status === "accepted") {
          setProposalStatus("accepted");
          setProposalARDate(
            moment(response.data.data.accept_reject_date).format("MM-DD-YYYY")
          );
          toast("Proposal accepted", { type: "success" });
          // send email
          try {
            axios
              .post("http://localhost:3001/send", {
                data: {
                  name: response.data.data.client_name,
                  type: "accepted",
                },
              })
              .then((response) => {
                console.log(`Sent email notification...`);
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (error) {
            console.log(error);
            // console.log(`Couldn't back online email notification.`);
          }
        } else {
          setProposalStatus("rejected");
          setProposalARDate(
            moment(response.data.data.accept_reject_date).format("MM-DD-YYYY")
          );
          toast("Proposal declined", { type: "error" });
          // send email
          try {
            axios
              .post("http://localhost:3001/send", {
                data: {
                  name: response.data.data.client_name,
                  type: "rejected",
                },
              })
              .then((response) => {
                console.log(`Sent email notification...`);
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (error) {
            console.log(error);
            // console.log(`Couldn't back online email notification.`);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleModalShow = (modalType) => {
    setModalType(modalType);
    setOpenModal(!openModal);
  };

  const rejectProposal = () => {
    proposalResponse(proposal_id, rejectedStatus, acceptRejectDate);
    handleModalShow(null);
  };

  // Create our number formatter.
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const acceptProposal = (signature) => {
    if (signature !== null || signature !== "") {
      proposalResponse(proposal_id, "accepted", acceptRejectDate, signature);
    }
    handleModalShow(null);
  };

  const fetchProposalInfo = async () => {
    axios({
      method: "get",
      url: `https://data.mintleafmarketing.com/items/proposals?filter[proposal_id]=${proposalId}`,
      headers: {
        Authorization: `Bearer 3db0f55f-ed56-4c51-84d0-5870a9aea4be`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.data.data[0]);
        if (response.data.data[0].status == "draft") {
          setIsDraft(true);
        } else {
          setProposalData(response.data.data[0]);
          setIsLoading(false);
          setProposalStatus(response.data.data[0].status);
          setProposalId(response.data.data[0].id);
          setProposalARDate(
            moment(response.data.data[0].accept_reject_date).format(
              "MM-DD-YYYY"
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProposalInfo();
  }, []);

  useEffect(() => {
    if (modalType !== null) {
      handleModalShow(modalType);
    }
  }, [modalType]);

  return !isDraft ? (
    <div className="timesheetContainer">
      <Toaster position="bottom-right" toastOptions={{ duration: 1500 }} />
      <div className="navContainer">
        <img style={Styles.MintLeafLogo} src={MintLeafLogo} />
        <div style={Styles.navRight}>
          {isLoading ? (
            <Skeleton />
          ) : proposalStatus === "rejected" || proposalStatus === "accepted" ? (
            <span
              style={{
                color: "#aaa",
                fontSize: ".75rem",
                margin: "0.75rem 0",
                marginRight: "3vw",
              }}
            >
              This proposal was {proposalStatus} on {proposalARDate}
            </span>
          ) : (
            <div>
              <button
                onClick={() => setModalType("rejected")}
                onMouseEnter={() => setDeclineHover(true)}
                onMouseLeave={() => setDeclineHover(false)}
                style={
                  declineHover
                    ? Styles.declineButtonHovered
                    : Styles.declineButton
                }
              >
                Decline
              </button>

              {openModal && (
                <div style={Styles.modalContainer}>
                  <ProposalPageModal
                    modalType={modalType}
                    handleModalShow={handleModalShow}
                    acceptProposal={acceptProposal}
                    rejectProposal={rejectProposal}
                  />
                  <div
                    style={Styles.modalOverlay}
                    onClick={() => {
                      setOpenModal(false);
                      setModalType(null);
                    }}
                  >
                    {" "}
                  </div>
                </div>
              )}

              <button
                onClick={() => setModalType("accepted")}
                onMouseEnter={() => setAcceptHover(true)}
                onMouseLeave={() => setAcceptHover(false)}
                style={
                  acceptHover ? Styles.acceptButtonHovered : Styles.acceptButton
                }
              >
                Accept Proposal
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="header">
        <img className="accent" src={Accent} />
        <div style={Styles.proposalHeadContainer}>
          <span style={Styles.timeSheetHeading}>Proposal</span>
          <h1 className="proposalTitle">
            {!isLoading && proposalData.title.replace(/<(.|\n)*?>/g, "")}{" "}
          </h1>
        </div>
      </div>
      <hr className="divider" />
      <div style={Styles.content}>
        {/* sections */}
        <div>
          {!isLoading &&
            proposalData.section.map((section, index) => {
              return (
                <div style={Styles.proposal} key={index}>
                  <h2>{section.header}</h2>
                  <div dangerouslySetInnerHTML={{ __html: section.body }}></div>
                </div>
              );
            })}
        </div>
      </div>

      <div style={Styles.feeSectionContainer}>
        <h2 style={{ margin: "0px 7.5vw" }}>Fee Summary</h2>
        <div
          style={Styles.feeSummaryNotes}
          dangerouslySetInnerHTML={{ __html: proposalData.fee_summary_notes }}
        ></div>
        <div style={{ margin: "0px 7.5vw" }}>
          <h4 style={Styles.deliverablesBadge}>Deliverables:</h4>
          <div>
            {!isLoading &&
              proposalData.deliverables.map((deliverable, index) => {
                return (
                  <div style={{ margin: "1rem 0" }} key={index}>
                    <div style={Styles.namePriceContainer}>
                      <span style={Styles.delivName}>
                        {deliverable.item_name.replace(/<(.|\n)*?>/g, "")}
                      </span>
                      <span style={Styles.price}>
                        {formatter.format(deliverable.price)}
                      </span>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: deliverable.description,
                      }}
                      style={Styles.delivDes}
                    ></div>
                    <br />
                  </div>
                );
              })}
          </div>
          <hr style={{ width: "100%" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "2rem",
            }}
          >
            <span style={Styles.totalAdded}>
              Total:{" "}
              {!isLoading &&
                formatter.format(
                  proposalData.deliverables.reduce((acc, curr) => {
                    return acc + curr.price;
                  }, 0)
                )}
            </span>
          </div>
        </div>
      </div>

      <div>
        {!isLoading && proposalData?.terms_and_conditions ? (
          <div
            style={{
              margin: " 7.5vw",
              paddingTop: "0rem",
              paddingBottom: "5rem",
            }}
          >
            <h2>Terms and Conditions</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: proposalData.terms_and_conditions,
              }}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div>
      <h1 style={{ marginLeft: "1rem" }}>Page Unavailable</h1>
    </div>
  );
};
const Styles = {
  proposalHeadContainer: {
    display: "grid",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "2rem",
  },
  MintLeafLogo: {
    width: "auto",
    height: "2rem",
  },
  navRight: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0",
    width: "100%",
  },
  declineButton: {
    backgroundColor: "#fff",
    color: "#aaa",
    border: "1px solid #aaa",
    borderRadius: "5px",
    padding: ".5rem 1rem",
    marginRight: "1rem",
    fontSize: "1rem",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
  },
  declineButtonHovered: {
    backgroundColor: "#e8e8e8",
    color: "#aaa",
    border: "1px solid #aaa",
    borderRadius: "5px",
    padding: ".5rem 1rem",
    marginRight: "1rem",
    fontSize: "1rem",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
  },
  acceptButton: {
    backgroundColor: "#3DE0A0",
    color: "white",
    border: "1px solid #3DE0A0",
    borderRadius: "5px",
    fontSize: "1rem",
    padding: ".5rem 1rem",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
  },
  acceptButtonHovered: {
    backgroundColor: "white",
    color: "#3DE0A0",
    border: "1px solid #3DE0A0",
    borderRadius: "5px",
    padding: ".5rem 1rem",
    fontSize: "1rem",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
  },
  timeSheetHeading: {
    fontWeight: "700",
    color: "#FF7DD2",
    fontSize: "1rem",
  },
  total: {
    fontSize: "2.5rem",
    color: "#40E0A0",
    lineHeight: "2.5rem",
    fontWeight: "900",
  },
  timeRounded: {
    color: "#DFDFDF",
  },
  content: {
    display: "grid",
    margin: "0 7.5vw",
    color: "#3d3d3d",
  },
  projects: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "2rem",
    paddingBottom: "2rem",
  },
  dateContainer: {
    display: "flex",
    backgroundColor: "#f4f4f4",
    width: "5rem",
    height: "5rem",
    minWidth: "5rem",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "4px",
    color: "#3d3d3d",
    justifyContent: "center",
    padding: "0.25rem",
  },
  datebox: {
    fontWeight: "700",
    textTransform: "uppercase",
    letterSpacing: ".075em",
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  timeDescriptionContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "1rem",
    marginLeft: "1rem",
    color: "#3d3d3d",
  },
  stopWatch: {
    height: "1.5rem",
    width: "auto",
    filter:
      "invert(100%) sepia(47%) saturate(3119%) hue-rotate(76deg) brightness(98%) contrast(79%)",
  },
  descriptionIcon: {
    height: "1.5rem",
    width: "auto",
    filter:
      "invert(72%) sepia(31%) saturate(3049%) hue-rotate(286deg) brightness(104%) contrast(101%)",
  },
  date: {
    fontSize: "1.25rem",
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    backgroundColor: "#f4f4f4",
    color: "#3d3d3d",
    lineHeight: "1.5rem",
    fontWeight: "700",
    textAlign: "center",
  },
  modalContainer: {
    display: "grid",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    justifyItems: "center",
    alignItems: "center",
  },
  modalOverlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    width: "100%",
    height: "100vh",
    position: "fixed",
    top: "0",
    left: "0",
  },
  feeSectionContainer: {
    backgroundColor: "#f8f8f8",
    display: "grid",
    margin: "2.5rem 0",
    padding: "2rem 0",
    marginBottom: "0",
  },
  feeSummaryNotes: {
    margin: ".5vw 9vw",
  },
  delivName: {
    fontSize: "1rem",
    fontWeight: "700",
    lineHeight: ".5rem",
    margin: "0",
    lineHeight: "1.5rem",
  },
  namePriceContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
  },
  price: {
    fontWeight: "700",
  },
  totalAdded: {
    fontWeight: "700",
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    margin: "0.5rem 0",
    lineHeight: "1.5rem",
  },
  deliverablesBadge: {
    backgroundColor: "#e2e2e2",
    padding: "0.5rem 1rem",
    width: "fit-content",
    borderRadius: "10px",
  },
};

export default ProposalPage;
