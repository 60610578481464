import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {useAuthHeader, useAuthUser} from 'react-auth-kit'
import ViewAllTimeSheetsBox from './ViewAllTimeSheetsBox';
import Nav from '../Navigation/Nav'
import AngryChinaMom from '../../../img/real_tax_documents.png'
import { fetchTimeSheetData } from '../../../helpers/api';
import Spinner from '../../Spinner';
import SideNav from '../SideNav/SideNav';

const ViewAllTimeSheet = () => {
    const urlCall = 'https://data.mintleafmarketing.com/items/time_entry?*.*';
    const authHeader = useAuthHeader();
    const user = useAuthUser();
    const [allTimeSheetsData, setAllTimeSheetsData] = useState([])
    const [totalTimeSpent, setTotalTimeSpent] = useState(0)
    const [totalCombied, setTotalCombied] = useState('')
    const [loading, setLoading] = useState(true);
    const [hidePrevious, setHidePrevious] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [activeTimer, setActiveTimer] = useState(false);
    const [timerId, setTimerId] = useState(null);
    const [show, setShow] = useState(false);
  
    const timerDropDownToggle = () => {
      setShow(!show)
    }

  
    useEffect(() => {
        fetchTimeSheetData(authHeader()).then(data => {
        setAllTimeSheetsData(data)
        setLoading(false);
        }).catch((error) => {
            console.log(error)
        })
    }, [])

  return (
    <div>
        <Nav hidePrevious={hidePrevious} showForm={showForm} activeTimer={activeTimer} timerId={timerId} show={show} timerDropDownToggle={timerDropDownToggle}/>
        <SideNav/>
        {!loading ?
        <div style={Styles.allSheetsContainer}>
        {typeof(allTimeSheetsData) !== 'undefined' && allTimeSheetsData.length > 0 ? allTimeSheetsData.map((data) => {
            const totalTime = data.time_entry.reduce((acc, curr) => {
                // add hours minutes and seconds together
                const totalSeconds = parseInt(curr.hours)*3600 + parseInt(curr.minutes)*60 + parseInt(curr.seconds);
                return acc + totalSeconds;
            }, 0);
            return (
                <ViewAllTimeSheetsBox data={data} key={data.id} totalTimeSpent={totalTime}/>
                )   
            }
            ) : <div>
        <p>No entries...? Why you no do your work!?</p>
        <img style={{width:'100%', maxWidth:'450px'}} src={AngryChinaMom} />
      </div>
    }  </div> : <div><Spinner/></div>}
    </div>
  )
}

const Styles = {
    allSheetsContainer: {
        display: 'grid',
        justifyContent: 'center',
        justifyItems: 'center',
        marginTop: '100px',
        marginLeft: '15%'
    }
}
export default ViewAllTimeSheet