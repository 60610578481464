import React, {useEffect, useState} from 'react';
import {useAuthUser} from 'react-auth-kit'
import {Link, useNavigate} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice, faGauge } from '@fortawesome/free-solid-svg-icons'

const Styles = {
  SideNavContainer: {
    display: 'grid',
    width: '15%',
    position: 'fixed',
    left: '0px',
    top: '0',
    height: '100%',
    justifyItems: 'center',
    backgroundColor: '#a2a392',
    zIndex: '101',
  },
  aviContainer: {
    display: 'flex',
    marginTop: '.25rem',
    width: '100%'
  },
  avatarIcon: {
    color: '#f0efeb',
    fontWeight: '700',
    width: '4rem',
    height: '2.5rem',
    lineHeight: '1rem',
    width: '25%'
  },
  logoutButton: {
    backgroundColor: '#C0BEAB',
    color: '#f0efeb',
    border: '1px solid transparent',
    cursor: 'pointer',
    fontWeight: '700',
    width: '4rem',
    height: '2.5rem',
    lineHeight: '1rem',
    width:'75%'
  },
  SideMenu: {
    display:'grid', 
    height: '15vh',
    width: '100%',
    textAlign: 'center',
  },
  SideMenuButtons: {
    backgroundColor: '#a2a392',
    border: '.5px solid black',
    cursor: 'pointer',
    color: '#f0efeb',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
  },
  SideMenuButtonsHovered: {
    backgroundColor: '#a2b392',
    border: '.5px solid black',
    cursor: 'pointer',
  },
  profileLogout: {
    display: 'flex',
    width: '100%',
    alignItems: 'end',
    marginBottom: '1rem'
  }
}

const SideNav = () => {
  const [showLogOut, setShow] = useState(false)

  const toggleAvi = () => {
    setShow(!showLogOut)
  }

  const user = useAuthUser();
  const navigate = useNavigate();

  return (
    <div style={Styles.SideNavContainer}>
      <div style={Styles.SideMenu}>
        <Link to='/dashboard' style={Styles.SideMenuButtons}><FontAwesomeIcon icon={faGauge} />Dashboard</Link>
        <Link to='/timesheets' style={Styles.SideMenuButtons}><FontAwesomeIcon icon={faFileInvoice} />Timesheets</Link>
        {/* <Link to='/settings' style={Styles.SideMenuButtons}>Settings</Link> */}
      </div>
        <div style={Styles.aviContainer}>
          <div style={Styles.profileLogout}>
              <img 
              onClick={toggleAvi}
              style={Styles.avatarIcon} 
              src={user().avatar !== null ? `https://data.mintleafmarketing.com/assets/${user().avatar.id}` : `https://ui-avatars.com/api/?background=d3d3d3&color=6b705c&name=${user().email.charAt(0)}`}/>
         
              <button style={Styles.logoutButton} onClick={() => navigate('/logout')}>
                Logout
              </button>
          </div>
        </div>
    </div>
  )
}

export default SideNav