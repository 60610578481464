import React, {useEffect, useState, useRef, useContext} from 'react'
import Timer from './Timer';
import PreviousProjects from './PreviousProjects';
import Form from './Form';
import axios from 'axios';
import moment from 'moment';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import timeRunningIcon from '../../../svg/running-lt.svg';
import timeNotRunningIcon from '../../../svg/not-running-lt.svg';
import pausedFavicon from '../../../svg/favicon.ico';
import runningFavicon from '../../../svg/favicon-two.ico';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast';
import { TimerContext } from '../../../contexts/TimerContext';

const Styles = {
  timerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "25vw",
    minWidth: '360px',
    backgroundColor: "#b7b7a4",
    padding: "1rem",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
    alignSelf: 'start'
  },
  dropdownButtonContainer: {
    display:'flex',
    justifyContent: 'flex-end',
    height: '50px'
  },
  newTimerButton: {
    backgroundColor: '#a5a58d',
    borderRadius: '4px',
    color: '#f0efeb',
    border: '1px solid transparent',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'pointer',
    alignSelf: 'center',
    marginRight: '7.5vw',
    fontWeight: '700',
    width: '8rem',
    lineHeight: '1rem',
  },
  newTimerButtonHovered: {
    backgroundColor: '#C0BEAB',
    borderRadius: '4px',
    color: '#f0efeb',
    border: '1px solid white',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'pointer',
    alignSelf: 'center',
    marginRight: '7.5vw',
    fontWeight: '700',
    width: '8rem',
    lineHeight: '1rem'
  },
  newTimerImg: {
    height: '1.25rem'
  },
  innerButtonConditional: {
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.25rem .5rem',
    fontFamily: 'IBM Plex Mono, monospace',
  }
};

const TimerDropper = ({hidePrevious, showForm, show, activeTimer, timerDropDownToggle, passedHours, passedMinutes, passedSeconds}) => {
    const [isActive, setIsActive] = useState(false);
    const [seconds, setSeconds] = useState(passedSeconds);
    const [minutes, setMinutes] = useState(passedMinutes);
    const [hours, setHours] = useState(passedHours);
    const [projectInfo, setProjectInfo] = useState([]);
    const [previousProjects, setPreviousProjects] = useState({});
    const favicon = document.getElementById('favicon');
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef(null);
    const [timeActiveTimer, setActiveTimer] = useState(activeTimer);
    const [timeHidePrevious, setHidePrevious] = useState(hidePrevious);
    const [timeShowForm, setShowForm] = useState(showForm);
    const authHeader = useAuthHeader();
    const user = useAuthUser();
    const {currentTimer, setCurrentTimer} = useContext(TimerContext);
    // const [currentSeconds, setCurrentSeconds] = useState(currentTimer.seconds);

    const callURL = 'https://data.mintleafmarketing.com/items/time_entry'

    useEffect(() => {
      if (typeof(currentTimer) !== 'undefined' && currentTimer !== null) {
        //console.log('currentTimer', currentTimer)
        // if currentTimer.running_since is not null, break that down into hours, minutes, seconds
        if (typeof(currentTimer.running_since) !== 'undefined' && currentTimer.running_since !== null) {
          const then = moment(currentTimer.running_since);
          const now = moment();
          // get seconds between now and then 
          const secondsBetween = now.diff(then, 'seconds');
          // add currentTimer.seconds, currentTimer.minutes, currentTimer.hours together to get total seconds
          const totalSeconds = secondsBetween + parseInt(currentTimer.seconds) + parseInt(currentTimer.minutes * 60) + parseInt(currentTimer.hours * 3600);
          // display as HH:mm:ss
          // const newDisplayTime = moment.utc(totalSeconds * 1000).format('HH:mm:ss');
          // console.log('newDisplayTime', newDisplayTime)

          var dur = moment.duration(totalSeconds, 'seconds');
          var hours = Math.floor(dur.asHours());
          var mins  = Math.floor(dur.asMinutes()) - hours * 60;
          var sec   = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;

          // add leading zeros if needed to each
          if (parseInt(hours) < 10) {
            hours = "0" + parseInt(hours);
          }
          if (parseInt(mins) < 10) {
            mins = "0" + parseInt(mins);
          }
          if (parseInt(sec) < 10) {
            sec = "0" + parseInt(sec);
          }

          const newDisplayTime = hours + ":" + mins + ":" + sec;
          
          // split hours, minutes, seconds into separate variables
          const newHours = newDisplayTime.split(':')[0];
          const newMinutes = newDisplayTime.split(':')[1];
          const newSeconds = newDisplayTime.split(':')[2];
          // set state to new values
          setHours(newHours);
          setMinutes(newMinutes);
          setSeconds(newSeconds);
        } else {
          setSeconds(currentTimer.seconds);
          setMinutes(currentTimer.minutes);
          setHours(currentTimer.hours);
        }
        setProjectInfo(currentTimer);
        setIsActive(currentTimer.isRunning);
        setActiveTimer(true);
      } else {
        setActiveTimer(false);
        setSeconds('00');
        setMinutes('00');
        setHours('00');
        setShowForm(false)
        setHidePrevious(true)
      }
    }, [currentTimer]);
    
    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${callURL}?sort=-date_updated&filter[status]=draft&filter[team]=${user().team.id}`,
        headers: { 
          'Authorization': `${authHeader()}`
        }
      })
      .then(function (response) {
        setPreviousProjects(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const handleClickOutside = (event) => {
      if(show) {
        if (ref.current && !ref.current.contains(event.target)) {
          timerDropDownToggle()
        }
      }
    }
 
  const createNewTimer = async () => {
    var config = {
      method: 'post',
      url: callURL,
      headers: { 
        'Authorization': `${authHeader()}`
      },
      data : {
        "date": moment().format(),
        "hours":"00",
        "minutes":"00",
        "seconds":"00",
        "running_since": moment().format(),
        "isRunning": true,
        "team":user().team.id,
        "user_updated":user().id,
      }
    };

    axios(config)
    .then(function (response) {
      setActiveTimer(true);
      console.log('timerId: ' + response.data.data.id);
      setShowForm(true);
      setCurrentTimer({...currentTimer, ...response.data.data});
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const updateTime = async (data) => {
    axios(
      {
        method: 'patch',
        url: `${callURL}/${currentTimer.id}`,
        headers: { 
          'Authorization': `${authHeader()}`, 
          'Content-Type': 'application/json'
        },
        data : data
      }
    )
    .then(function (response) {
      toast('Updated', {type: 'success'})
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
      toast('Error', {type: 'error'})
    });
  }

  const deleteProject = async () => {
    axios(
      {
        method: 'delete',
        url: `${callURL}/${currentTimer.id}`,
        headers: { 
          'Authorization': `${authHeader()}`, 
          'Content-Type': 'application/json'
        },
      }
    )
    .then(function (response) {
      toast(`Deleted entry`, { type: 'success'});
      handleBackChange();
      fetchData();
      setActiveTimer(false);
    })
    .catch(function (error) {
      console.log(error);
      toast(`Could not delete`, { type: 'error'});
    });
  }
  
  useEffect(() => {

    document.addEventListener('click', handleClickOutside, true)

      // if (!isActive && timeActiveTimer) {
      //   updateTime({
      //     "hours":currentTimer.hours,
      //     "minutes":currentTimer.minutes,
      //     "seconds":currentTimer.seconds,
      //     "running_since":null,
      //     "isRunning":false,
      //     "user_updated":user().id,
      //   });
      // }

      if (isActive) {
        favicon.href = runningFavicon;
        document.title = `${hours}:${minutes}:${seconds}`
      } else {
        favicon.href = pausedFavicon;
        document.title = `Timer App (${hours}:${minutes}:${seconds})`
      }

      if (isActive) {
        setShowForm(true);
        setHidePrevious(false)
      }
    
      
    if (isActive) {
      let intervalId;
      intervalId = setInterval(() => {

        if(seconds == null) {
          setSeconds("00")
        }
        if(minutes== null) {
          setMinutes("00")
        }
        if(hours == null) {
          setHours("00")
        }

        if (isNaN(hours)) {
          setHours("00");
        }
        if (isNaN(minutes)) {
          setMinutes("00");
        }
        if (isNaN(seconds)) {
          setSeconds("00");
        }

        if (parseInt(seconds) < 9) {
          setSeconds("0" + (parseInt(seconds) + 1));
        } else {
          setSeconds(parseInt(seconds) + 1);
        }
        if (parseInt(seconds) >= 59) {
          setSeconds("00");
          if (parseInt(minutes) < 9) {
            setMinutes("0" + (parseInt(minutes) + 1));
          } else {
            setMinutes(parseInt(minutes) + 1);
          }
        }
        // if (parseInt(minutes) >= 59) {
        //   setMinutes("00");
        //   setHours("0" + (parseInt(hours) + 1));
        // }

        if (parseInt(seconds) >= 59 && parseInt(minutes) >= 59) {
          setMinutes("00");
          setSeconds("00");
          if (parseInt(hours) < 9) {
            setHours("0" + (parseInt(hours) + 1));
          } else {
            setHours(parseInt(hours) + 1);
          }
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
        document.removeEventListener('click', handleClickOutside, true)
      }
    } else {
      fetchData();
    }


  }, [isActive, seconds, minutes, hours, ref, show, currentTimer]);


    // update the time when child is clicked
    const handleChildClick = (projectInfo) => {
      setActiveTimer(true);
      setHidePrevious(!timeHidePrevious);
      setHours(projectInfo.hours);
      setMinutes(projectInfo.minutes);
      setSeconds(projectInfo.seconds);
      setProjectInfo(projectInfo);
      setShowForm(true);
      setCurrentTimer({...currentTimer, ...projectInfo});
    }

    // update boolean state when child is clicked
    const handleToggleClick = () => {
      setIsActive(!isActive);
      console.log('running: '+!isActive);

      if (!timeActiveTimer) {
        createNewTimer();
      }

      let runningSince = null;

      if (!isActive) {
        runningSince = moment().format();
      }

      if(!isActive && timeActiveTimer) {
        // Timer is running
        console.log('sending play');
        updateTime({
          "hours":hours,
          "minutes":minutes,
          "seconds":seconds,
          "running_since": moment().format(),
          "isRunning":true,
          "user_updated":user().id,
        });
        setCurrentTimer({...currentTimer, ...{
          "hours":hours,
          "minutes":minutes,
          "seconds":seconds,
          "running_since": moment().format(),
          "isRunning":true,
          "user_updated":user().id,
        }});
      }
      if(isActive && timeActiveTimer) {
        // Timer is not running
        console.log('sending pause');
        updateTime({
          "hours":hours,
          "minutes":minutes,
          "seconds":seconds,
          "running_since": null,
          "isRunning":false,
          "user_updated":user().id,
        });
        setCurrentTimer({...currentTimer, ...{
          "hours":hours,
          "minutes":minutes,
          "seconds":seconds,
          "running_since": null,
          "isRunning":false,
          "user_updated":user().id,
        }});
      }

      // update the currentTimer
      // setCurrentTimer({...currentTimer, 
      //   "hours":hours,
      //   "minutes":minutes,
      //   "seconds":seconds,
      //   "running_since": runningSince,
      //   "isRunning":!isActive,
      //   "user_updated":user().id,
      // });

    }

    // update the value of seconds, minutes, hours
    const handleSecondsChange = (event) => {
      setSeconds(event.target.value);
    }
    const handleMinutesChange = (event) => {
      setMinutes(event.target.value);  
    }
    const handleHoursChange = (event) => {
      setHours(event.target.value);
    }

    const handleBackChange = () => {
      setCurrentTimer(null);
      setActiveTimer(false)
      setShowForm(false)
      setHidePrevious(true)
      setSeconds("00")
      setMinutes("00")
      setHours("00")
    }

    const handleFocusLost = (event) => {

      if(event.target.getAttribute('timetype') === "seconds") {
       if (seconds == "") {
        setSeconds("00");
        updateTime({"seconds":"00"});
        setCurrentTimer({...currentTimer, ...{"seconds":"00"}});
        }
        if (parseInt(seconds) < 10) {
          setSeconds(event.target.value.padStart(2, '0'));
          updateTime({"seconds":event.target.value.padStart(2, '0')})
          setCurrentTimer({...currentTimer, ...{"seconds":event.target.value.padStart(2, '0')}});
        }
      }
      if(event.target.getAttribute('timetype') === "minutes") {
       if (minutes == "") {
        setMinutes("00");
        updateTime({"minutes":"00"});
        setCurrentTimer({...currentTimer, ...{"minutes":"00"}});
        return;
        }
        if (parseInt(minutes) < 10) {
          setMinutes(event.target.value.padStart(2, '0'));
          updateTime({"minutes":event.target.value.padStart(2, '0')})
          setCurrentTimer({...currentTimer, ...{"minutes":event.target.value.padStart(2, '0')}});
          return;
        }
      }
      if(event.target.getAttribute('timetype') === "hours") {
       if (hours == "") {
          setHours("00");
          updateTime({"hours":"00"});
          setCurrentTimer({...currentTimer, ...{"hours":"00"}});
          return;
        }
        if (parseInt(hours) < 10) {
          setHours(event.target.value.padStart(2, '0'));
          updateTime({"hours":event.target.value.padStart(2, '0')})
          setCurrentTimer({...currentTimer, ...{"hours":event.target.value.padStart(2, '0')}});
          return;
        }
      }
      if (isNaN(hours) || parseInt(hours) < 0 || parseInt(hours) % 1 != 0) {
        setHours("00");
        updateTime({"hours":"00"});
        setCurrentTimer({...currentTimer, ...{"hours":"00"}});
        return;
      }
      if (isNaN(minutes) || parseInt(minutes) < 0 || parseInt(minutes) % 1 != 0) {
        setMinutes("00");
        updateTime({"minutes":"00"});
        setCurrentTimer({...currentTimer, ...{"minutes":"00"}});
        return;
      }
      if (isNaN(seconds) || parseInt(seconds) < 0 || parseInt(seconds) % 1 != 0) {
        setSeconds("00");
        updateTime({"seconds":"00"});
        setCurrentTimer({...currentTimer, ...{"seconds":"00"}});
        return;
      }
      updateTime({
        "hours":hours,
        "minutes":minutes,
        "seconds":seconds
      });
      setCurrentTimer({...currentTimer, ...{
        "hours":hours,
        "minutes":minutes,
        "seconds":seconds,
      }});
    }

   return (
    <div ref={ref}>
      <div style={Styles.dropdownButtonContainer}>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={isHovered ? Styles.newTimerButtonHovered : Styles.newTimerButton}
        onClick={timerDropDownToggle}
        >
        
          {(isActive && timeActiveTimer) && 
          <div style={Styles.innerButtonConditional}>
            <span style={{fontFamily: 'IBM Plex Mono, monospace'}}>{`${hours !== null && currentTimer != null ? hours : "00"}:${minutes !== null  && currentTimer != null ? minutes : "00"}:${seconds !== null  && currentTimer != null ? seconds : "00"}`}</span>
            <img src={timeRunningIcon} style={Styles.newTimerImg}/>
          </div>
            }
          {(!isActive && timeActiveTimer) && 
          <div style={Styles.innerButtonConditional}>
            <span style={{fontFamily: 'IBM Plex Mono, monospace'}}>{`${hours !== null && currentTimer != null ? hours : "00"}:${minutes !== null  && currentTimer != null ? minutes : "00"}:${seconds !== null  && currentTimer != null ? seconds : "00"}`}</span>
            <img src={timeNotRunningIcon} style={Styles.newTimerImg}/>
          </div>
          }
          {!timeActiveTimer && 
          <div style={Styles.innerButtonConditional}>
            <span style={{width:'max-content'}}>New Timer</span>
            <FontAwesomeIcon style={{color:'#fff'}} icon={faCirclePlus} />
            </div>
            }
      </button>

      </div>
      <div style={{marginRight:'7.5vw'}} className="dropdown-container">
        {show && 
          <div style={Styles.timerContainer}>
            <Timer
              handleToggleClick={handleToggleClick}
              handleSecondsChange={handleSecondsChange}
              handleMinutesChange={handleMinutesChange}
              handleHoursChange={handleHoursChange}
              handleFocusLost={handleFocusLost}
              isRunning={isActive}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
              activeTimer={timeActiveTimer}
              />

            {timeShowForm && <Form deleteProject={deleteProject} handleBackChange={handleBackChange} projectInfo={projectInfo} isRunning={isActive}/>}
            {timeHidePrevious && (
              <PreviousProjects
                handleChildClick={handleChildClick}
                data={previousProjects}
              />
            )}
          </div>
        }
      </div>
    </div>
  );
}

export default TimerDropper;