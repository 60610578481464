import axios from 'axios';
import toast from 'react-hot-toast'
import moment from 'moment';

export const fetchData = async (endpoint, fields, filter, auth) => {
    var config = {
        headers: {
            'Authorization': `${auth}`
        },
        params: {
            'fields': fields,
            'filter': filter
        },
        url: `https://data.mintleafmarketing.com/items/${endpoint}`
    };
    axios(config)
    .then(function (response) {
        console.log(response);
    })
    .catch(function (error) {
        console.log(error);
        toast(error.response.data.message, { type: 'error' });
    });
}


export const fetchClients = async () => {
    var data = JSON.stringify({
        query: `query {
        business(id: "QnVzaW5lc3M6ZDZhYjM1YTUtZDVjMS00OGJlLWJkNDktMDMyYTRiNDg2ZWIw") {
          id
          customers(page: 1, sort: [NAME_ASC],) {
            pageInfo {
              currentPage
              totalPages
              totalCount
            }
            edges {
              node {
                id
                name
                email
                internalNotes
              }
            }
          }
        }
      }`,
        variables: {}
      });

      return axios({
        method: 'post',
        url: 'https://gql.waveapps.com/graphql/public',
        headers: { 
          'Authorization': `Bearer uyMGjr7N3IA7uGpR2lOrmShwm4xJ5r`, 
          'Content-Type': 'application/json'
        },
        data : data
      })
    .then(function (response) {
      // map array of objects to array of objects with only id and name
      const clients = response.data.data.business.customers.edges.map(client => {
          return (
              client.node
          )
      })
    return clients;
    //   setClients(clients);
    })
    .catch(function (error) {
      console.log(error);
      toast('Clients could not be loaded', { type: 'error' });
    });
}

export const updateClient = async (client_id, client_name, timerId, auth) => {
      return axios(
        {
          method: 'patch',
          url: `https://data.mintleafmarketing.com/items/time_entry/${timerId}`,
          headers: { 
            'Authorization': `${auth}`, 
            'Content-Type': 'application/json'
          },
          data : {
              "client_name": client_name,
              "client_id": client_id,
          }
        }
      )
      .then(function (response) {
        toast('Client Updated', { type: 'success' });
        console.log(response.data);
      })
      .catch(function (error) {
        toast('Update Failed', { type: 'error' });
        console.log(error);
      });
    }

    export const fetchEntryData = async (auth) => {
        return axios({
            method: 'get',
            url: `https://data.mintleafmarketing.com/items/time_entry?filter[status]=draft&fields=*.*`,
            headers: { 
              'Authorization': `${auth}`
            }
        })
        .then(function (response) {
            return response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      }

export const fetchCurrentTimerForUser = async (auth, user_id) => {
    return axios({
        method: 'get',
        url: `https://data.mintleafmarketing.com/items/time_entry?filter[status]=draft&fields=*.*&filter[user_updated]=${user_id}&filter[isRunning]=true`,
        headers: { 
          'Authorization': `${auth}`
        }
    })
    .then(function (response) {
        //console.log(response.data);
        return response.data.data[0];
    }
    )
    .catch(function (error) {
        console.log(error);
        }
    );
}

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        //console.log(config.url);
        if(config.url == 'https://gql.waveapps.com/graphql/public'){
            return config;
        }
        const token = localStorage.getItem('_auth');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

axios.interceptors.response.use((response) => {
    return response
 },
 function (error) {
    const originalRequest = error.config;
    console.log(error);
    if (error.response.status === 401 && !originalRequest._retry) {
 
        originalRequest._retry = true;
        return axios.post('https://data.mintleafmarketing.com/auth/refresh',
            {
                "refresh_token": localStorage.getItem('_auth_refresh')
            })
            .then(res => {
                if (res.status === 201) {
                    // 1) put token to LocalStorage
                    // localStorageService.setToken(res.data);
                    localStorage.setItem('_auth_refresh', res.data.refresh_token);
                    localStorage.setItem('_auth', res.data.access_token);
 
                    // 2) Change Authorization header
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('_auth');
 
                    // 3) return originalRequest object with Axios.
                    return axios(originalRequest);
                }
            }).catch(err => {
                localStorage.removeItem('_auth');
                localStorage.removeItem('_auth_refresh');
                // window.location.href = '/login';
            });
    }
 
    // return Error object with Promise
    return Promise.reject(error);
 });
      // time entries calls
      export const deleteProject = async (id, auth) => {
         return axios(
          {
            method: 'delete',
            url: `https://data.mintleafmarketing.com/items/time_entry/${id}`,
            headers: { 
              'Authorization': `${auth}`, 
              'Content-Type': 'application/json'
            },
          }
        )
        .then(function (response) {
          console.log(response);
          toast(`Deleted Entry: ${id}`, { style: {color: '#fff', backgroundColor: '#00b894'} });
          return response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      }

      export const updateDate = async (date, id, auth) => {
        axios(
          {
            method: 'patch',
            url: `https://data.mintleafmarketing.com/items/time_entry/${id}`,
            headers: { 
              'Authorization': `${(auth)}`, 
              'Content-Type': 'application/json'
            },
            data : {
                "date": date,
            }
          }
        )
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    // view all time sheets js calls
    export const fetchTimeSheetData = async (auth) => {
      return axios({
          method: 'get',
          url: `https://data.mintleafmarketing.com/items/timesheet?fields=*.*`,
          headers: {
              'Authorization': `${auth}`
          }
      })
      .then(function (response) {
          console.log(response.data.data)
          return response.data.data
      })
      .catch(function (error) {
          console.log(error)
      })
  }

  // time sheet modal calls

  export const getTimeSheetDropDown = async (auth, user_teamId) => {
    var config = {
        method: 'get',
        url: `https://data.mintleafmarketing.com/items/time_entry?filter[status]=draft&groupBy[]=client_id,client_name&filter[client_id][_nnull]=true&filter[team]=${user_teamId}`,
        headers: { 
          'Authorization': `${(auth)}`,
        }
    }
    return axios(config)
    .then(function (response) {
      return response.data.data
    }).catch(e => {
        console.log(e)
    });
}


export const getClientLog = async (client, user_teamId, auth) => {
  var config = 
      {
          method: 'get',
          url: `https://data.mintleafmarketing.com/items/time_entry?filter[client_id]=${client}&filter[team]=${user_teamId}&filter[status]=draft`,
          headers: { 
            'Authorization': `${auth}`, 
          },
      }
      return axios(config)
      .then((response) => {
        console.log(response.data.data)
        return response.data.data
      })
      .catch(e => {
          console.log(e)
      })
}

export const getTimeSheetData = async (timesheet_id, auth) => {
  var config = {
      method: 'get',
      url: `https://data.mintleafmarketing.com/items/timesheet?filter[id]=${timesheet_id}&fields=*.*`,
      headers: {
        'Authorization': `${auth}`, 
      }
  }
  return axios(config)
  .then(function (response) {
      const timesheetInfo = (response.data.data[0]);
      // get total time spent
      // add up all the time spent and set it to the total time spent
      const totalTimeSpent = (timesheetInfo.time_entry.reduce((acc, curr) => {
          // add hours minutes and seconds together
          const totalSeconds = parseInt(curr.hours)*3600 + parseInt(curr.minutes)*60 + parseInt(curr.seconds);
          return acc + totalSeconds;
      }, 0));
      const rate = timesheetInfo.time_entry[0].rate;
      // get nice rounded hours number
      // create invoice in wave
      //console.log(timesheetInfo, roundedTime(totalTimeSpent), rate);
      createWaveInvoice(timesheetInfo, roundedTime(totalTimeSpent), rate);
  })
  .catch(function (error) {
      console.log(error);
  });
}
const roundedTime = (seconds) => {
  // convert total time spent to hours and round to the next 15 minute increment
  var duration = moment.duration(seconds, 'seconds');
  var minutes = Math.ceil(duration.asMinutes() / 15) * 15;
  var decimalMinutes = minutes / 60;
  return `${decimalMinutes}`
}


const createWaveInvoice = async (timesheetData, totalTime, rate) => {
  var data = JSON.stringify({
      query: `mutation ($input: InvoiceCreateInput!) {
      invoiceCreate(input: $input) {
        didSucceed
        inputErrors {
          message
          code
          path
        }
        invoice {
          id
          createdAt
          modifiedAt
          pdfUrl
          viewUrl
          status
          title
          subhead
          invoiceNumber
          invoiceDate
          poNumber
          customer {
            id
            name
            # Can add additional customer fields here
          }
          currency {
            code
          }
          dueDate
          amountDue {
            value
            currency {
              symbol
            }
          }
          amountPaid {
            value
            currency {
              symbol
            }
          }
          taxTotal {
            value
            currency {
              symbol
            }
          }
          total {
            value
            currency {
              symbol
            }
          }
          exchangeRate
          footer
          memo
          disableCreditCardPayments
          disableBankPayments
          itemTitle
          unitTitle
          priceTitle
          amountTitle
          hideName
          hideDescription
          hideUnit
          hidePrice
          hideAmount
          items {
            product {
              id
              name
              # Can add additional product fields here
            }
            description
            quantity
            price
            subtotal {
              value
              currency {
                symbol
              }
            }
            total {
              value
              currency {
                symbol
              }
            }
            account {
              id
              name
              subtype {
                name
                value
              }
              # Can add additional account fields here
            }
            taxes {
              amount {
                value
              }
              salesTax {
                id
                name
                # Can add additional sales tax fields here
              }
            }
          }
          lastSentAt
          lastSentVia
          lastViewedAt
        }
      }
    }`,
      variables: {
          "input": {
              "businessId":"QnVzaW5lc3M6ZDZhYjM1YTUtZDVjMS00OGJlLWJkNDktMDMyYTRiNDg2ZWIw",
              "customerId":`${timesheetData.client_id}`,
              "items":[
                  {
                      "productId":"QnVzaW5lc3M6ZDZhYjM1YTUtZDVjMS00OGJlLWJkNDktMDMyYTRiNDg2ZWIwO1Byb2R1Y3Q6Nzc1ODA0MzE=",
                      "quantity":totalTime < 1 ? 1 : totalTime,
                      "unitPrice":rate,
                      "description":"**Link to timesheet with work details can be found in notes section of this invoice**",
                  }
              ],
              "memo":`https://app.mintleafmarketing.com/timesheets/${timesheetData.id}`,
          }
      }
    });
    
    var config = {
      method: 'post',
      url: 'https://gql.waveapps.com/graphql/public',
      headers: { 
        'Authorization': `Bearer uyMGjr7N3IA7uGpR2lOrmShwm4xJ5r` , 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
      toast('Invoice created', { type: 'success' });
    })
    .catch(function (error) {
      toast('Error creating Invoice', { type: 'error' });
      console.log(error);
    });
}


// form calls 

export const updateData = async (defaultTimerId, auth, data) => {
  return axios(
    {
      method: 'patch',
      url: `https://data.mintleafmarketing.com/items/time_entry/${defaultTimerId}`,
      headers: { 
        'Authorization': `${auth}`, 
        'Content-Type': 'application/json'
      },
      data : data
    }
  )
  .then(function (response) {
    toast('Updated', {type: 'success'});
    console.log(response.data);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const updateTimeSheetModalData = async (timerId, auth, rate, description, startDate,seconds, minutes, hours) => {
  axios(
    {
      method: 'patch',
      url: `https://data.mintleafmarketing.com/items/time_entry/${timerId}`,
      headers: { 
        'Authorization': `${auth}`, 
        'Content-Type': 'application/json'
      },
      data : {
          "rate": rate !== '' ? parseInt(rate) : parseInt(0),
          "description": description,
          "date": startDate,
          'seconds': seconds,
          'minutes': minutes,
          'hours': hours,
      }
    }
  )
  .then(function (response) {
    console.log(response.data);
  })
  .catch(function (error) {
    console.log(error);
  });
}